import { TableColumn } from "../AssortmentIntelligence/types";

export type RowData = {
  image_url: string;
  title: string;
  asins?: number;
  tasks?: number;
  revenue?: number;
  revenue_change?: number;
  revenue_percentage?: number;
  units_sold?: number;
  units_sold_change?: number;
  units_sold_percentage?: number;
  conversion?: number;
  conversion_change?: number;
  conversion_percentage?: number;
  glance_views?: number;
  glance_views_change?: number;
  glance_views_percentage?: number;
  share_of_voice?: number;
  share_of_voice_change?: number;
  share_of_voice_percentage?: number;
  competitive_traffic?: number;
  competitive_traffic_change?: number;
  competitive_traffic_percentage?: number;
  avg_price?: number;
  avg_price_change?: number;
  avg_price_percentage?: number;
  ad_spend?: number;
  ad_spend_change?: number;
  ad_spend_percentage?: number;
  acos?: number;
  acos_change?: number;
  acos_percentage?: number;
  space_id?: string;
  space_name?: string;
  brand?: string;
  categories?: string[];
  space_ids?: string[];
};

export type ListOutput = {
  total: number;
  data: RowData[];
};

export type SalesAssistantHeaderData = {
  title: string;
  value_7d: number;
  value_7d_prev: number;
  value_28d: number;
  value_28d_prev: number;
  change_7d: number;
  change_28d: number;
  graph_data: number[][];
  categories: string[];
  type: string;
};

export type TabConfig = {
  title: string;
  value_name: string;
  unit?: "%" | "$" | undefined;
  type: string;
  data: DrawerQuestion[];
};

export type DrawerQuestion = {
  title: string;
  question_id: SalesAssistantInsight;
  closeable: boolean;
  default_closed: boolean;
};


export type ProductSpace = {
  id: string;
  name: string;
  image_url: string;
}

export enum SalesAssistantInsight {
  SHARE_OF_VOICE_PERFORMANCE = "share_of_voice_performance",
  SHARE_OF_VOICE_KEYWORDS = "share_of_voice_keywords",
  TOP_COMPETITORS = "top_competitors",
  COMPETITIVE_SPACE = "competitive_space",
  COMPETITORS_KEYWORDS = "competitors_keywords",
  MOST_SALES_PRICE_30D = "most_sales_price_30d",
  MOST_SALES_PRICE_90D = "most_sales_price_90d",
  WEEKLY_AD_SPEND = "weekly_ad_spend",
  DAILY_AD_SPEND = "daily_ad_spend",
  WEEKLY_CPC = "weekly_cpc",
  DAILY_CPC = "daily_cpc",
  WEEKLY_CONVERSION = "weekly_conversion",
  DAILY_CONVERSION = "daily_conversion",
}

export type SalesAssistantInsightOutput = {
  table_schema: TableColumn[];
  table_data: Record<string, any>[];
  graph_type: "table" | "line" | "columns"
};