import { RowData } from "../types.ts";
import { Chip, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import theme from "../../../themes/light.ts";

const InfoSection = ({
  data,
  type = "metadata",
}: {
  data?: RowData;
  type?: "metadata" | "product";
}) => {
  return (
    <Stack direction="column" spacing={1} alignItems="space-between">
      <Stack direction="row" spacing={1} alignItems="center">
        {data?.title ? (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              fontSize: "18px",
            }}
            variant="headline3"
            color={theme.palette.primary.main}
          >
            {data?.title}
          </Typography>
        ) : (
          <Skeleton
            variant="text"
            height={40}
            width={150}
            sx={{ borderRadius: "4px" }}
          />
        )}
      </Stack>
      {!data ? (
        <Skeleton
          variant="text"
          height={40}
          width={250}
          sx={{ borderRadius: "4px" }}
        />
      ) : type === "metadata" ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <Chip
            label={`${data?.asins} ASINS`}
            size="small"
            sx={{
              borderRadius: "4px",
              padding: "4px",
              textTransform: "capitalize",
            }}
          />
        </Stack>
      ) : (
        <Stack direction="row" spacing={1} alignItems="center">
          <Chip
            label={data?.brand}
            size="small"
            sx={{
              borderRadius: "4px",
              padding: "4px",
              textTransform: "capitalize",
            }}
          />
          <Tooltip title={data?.categories}>
            <Typography variant="caption" color="text.secondary">
              {`${data?.categories?.[0]} | ... | ${
                // @ts-ignore
                data?.categories?.at(-1)
                }`}
            </Typography>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
};

export default InfoSection;
