import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { convertNumberToShortFormat } from "../../../ResearchAssistant/utils";
import { renderToString } from "react-dom/server";
import { TableColumn } from "../../types";
import ChartTooltip from "../../../../components/ChartTooltip/ChartTooltip";

const colors = ["#88bf4d", "#e75454", "#f9d45c"];

interface SalesPerformanceProps {
  data: Record<string, any>[];
  schema: TableColumn[];
}

const SalesPerformance: FC<SalesPerformanceProps> = ({ data, schema }) => {
  const categories = useMemo(() => {
    return data.map((item) => item["Date"]);
  }, [data]);

  const series = useMemo(
    () => [
      {
        name: "Revenue",
        data: data.map((item) => item["Revenue"]),
        type: "area",
      },
      {
        name: "Ad Traffic Share",
        data: data.map((item) => item["Ad Traffic Share"]),
        type: "line",
      },
      {
        name: "Average Discount",
        data: data.map((item) => item["Average Discount"]),
        type: "line",
      },
    ],
    [data]
  );

  const options = useMemo(() => {
    return {
      chart: {
        type: "area",
        toolbar: { show: false, tools: { download: false } },
      },
      plotOptions: { area: { stacked: true } },
      dataLabels: {
        enabled: true,
        offsetY: -7.5,
        style: {
          fontSize: "12px",
          fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
          fontWeight: 500,
          colors: ["black"],
        },
        background: {
          enabled: false,
        },
        dropShadow: {
          enabled: true,
          top: 1,
          left: 1,
          blur: 1,
          opacity: 1,
          color: "#fff",
        },
        formatter: (val: number, opts?: any) => {
          const isRevenue = opts.seriesIndex === 0;
          return `${isRevenue ? "$" : ""}${convertNumberToShortFormat(val, !isRevenue)}`;
        },
      },
      colors: colors,
      stroke: {
        show: true,
        colors: colors,
        curve: "smooth",
        width: 2,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        position: "top",
        horizontalAlign: "right",
        fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
        fontSize: "14px",
        onItemClick: { toggleDataSeries: !!series?.[1] },
        markers: { fillColors: colors, offsetX: -3 },
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        custom: ({ seriesIndex, dataPointIndex }: any) =>
          renderToString(
            <ChartTooltip
              header={categories[dataPointIndex]}
              data={schema
                .filter((column) => column.name != "Date")
                .map((column, index) => ({
                  name: column.name,
                  value: `${
                    column.name === "Revenue"
                      ? `$${convertNumberToShortFormat(data[dataPointIndex][column.name])}`
                      : convertNumberToShortFormat(
                          data[dataPointIndex][column.name],
                          true
                        )
                  }`,
                  color: colors[index],
                }))}
              brightenColors={true}
            />
          ),
      },
      fill: {
        colors: "#dcebcb",
      },
      xaxis: {
        title: {
          text: "",
        },
        tickAmount: Math.min(categories.length, 26),
        categories: categories,
        labels: {
          style: {
            fontSize: "14px",
          },
        },
        axisBorder: { show: false },
        tooltip: { enabled: false },
      },
      yaxis: [
        {
          seriesName: "Revenue",
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        {
          seriesName: "Ad Traffic Share",
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
      ],
    };
  }, [series, colors, data]);

  return (
    <Box
      id="sales-performance-chart"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        width: "auto",
      }}
    >
      <ReactApexChart
        // @ts-ignore
        options={options}
        series={series}
        type="line"
        height={550}
        width={1200}
      />
    </Box>
  );
};

export default SalesPerformance;
