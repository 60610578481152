import apiRequest, {APIError} from "../../../../services/apiRequest.ts";
import {useQuery, UseQueryResult} from "react-query";



export const useAskLLM = ({ asin,user_question, period_view, space_id }:{
                                      asin?: string;
                                      user_question?:string;
                                      period_view?: string;
                                      space_id?: string;

                                    }): UseQueryResult<string, APIError> =>
  useQuery<string, APIError>(
    ["getAskLLM",asin,user_question, period_view, space_id],
    () => getAskLLM({ asin, user_question,period_view, space_id }),
    {
      refetchOnWindowFocus: false,
        enabled: !!asin && !!user_question,
        retry:3,
    }
  );


const getAskLLM = ({ asin,user_question, period_view, space_id }:
                                    {
                                      asin?: string;
                                      user_question?:string;
                                      period_view?: string;
                                      space_id?: string;

                                    }): Promise<string> => {

  return apiRequest<string>(
      `v1/sales_assistant/ask_llm`,
      "POST",
      {},
      {
          asin,
          user_question,
          space_id,
          period_view
      }
  );
};