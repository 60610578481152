import {
  Box,
  Card,
  Grid,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import NumberSection from "../Components/NumberSection.tsx";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import { InfoIconV2 } from "../../../assets/index.tsx";
import { renderToString } from "react-dom/server";
import { convertNumberToShortFormat } from "../../ResearchAssistant/utils.ts";
import "./CustomTooltip.css";
import theme from "../../../themev2.ts";
import { formatCurrencyOnlyNumber } from "../../InsightsV2/SummaryV2.tsx";
import { useEffect, useRef, useState } from "react";

const CustomTooltip = ({ series, labels, colors, dataPointIndex, arrow = true }: any) => {
  const getLabel = (value: number, index: number) => {
    const date = dayjs(value / 1000);
    const adjustedYear = date.year() - index;
    return date.year(adjustedYear).format("D MMM");
  };

  return (
    <div className="tooltip-div">
      {series.map((serie: any, index: number) => {
        return (
          <div className="tooltip-line" key={index}>
            {series[0].length > 1 && (
              <span key={index} className={`tooltip-key`}>
                {getLabel(labels[dataPointIndex], index)}
              </span>
            )}
            <span
              key={index}
              className={`tooltip-value`}
              style={
                series.length > 1
                  ? {
                    backgroundColor: colors[index],
                    padding: "2px 8px",
                    borderRadius: "14px",
                  }
                  : {}
              }
            >
              {serie[dataPointIndex]}
            </span>
          </div>
        );
      })}
      {arrow && <div className="tooltip-arrow"></div>}
    </div>
  );
};


function formatDateRange(start: number, end: number) {
  // start would be the last day of previous range so adding 1 day
  const startDate = dayjs(start).add(1, 'day');
  const endDate = dayjs(end);

  // Same month
  if (startDate.format('MMM') === endDate.format('MMM')) {
    return `${startDate.format('MMM D')}-${endDate.format('D')}`;
  }

  // Different months
  return `${startDate.format('MMM D')}-${endDate.format('MMM D')}`;
}

function getLastNWeeksRange(timestamps: number[] | string[], weeks_num: number, start_weeks_back: number = 0) {
  // Get last 4 timestamps from the array
  const lastN = timestamps.slice(-(weeks_num + 1 + start_weeks_back), start_weeks_back !== 0 ? -start_weeks_back : undefined);
  // Convert from microseconds to milliseconds for dayjs
  return formatDateRange(Number(lastN[0]) / 1000, Number(lastN[weeks_num]) / 1000);
}

const KPITootipText = {
  Revenue: `Total ordered revenue generated up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )}`,
  Units: `Total ordered units sold up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )}`,
  ACOS: `Advertising Cost of Sales (ACOS) as a percentage of total ad spend relative to revenue up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )}`,
  "Conversion Rate": `Representing the percentage of visitors who made a purchase up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )}`,
  "Ad Spend": `Total ad spend up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )}`,
  "Market Share": `The percentage of total sales in the market that your brand accounts for up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )}`,
  Traffic: `The ratio between impressions in the current year up to and including ${dayjs().format(
    "MMM. D, YYYY"
  )} and the previous year`,
};

const CardSkeleton = () => (
  <Box
    sx={{
      height: "110px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 12px",
      boxShadow:
        "0px 12px 45px rgba(44, 34, 76, 0.05), 0px 8px 20px rgba(44, 34, 76, 0.05)",
    }}
  >
    <Box
      sx={{
        width: "30%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {Array.from({ length: 3 }).map((_, index) => (
        <Skeleton
          key={`skeleton-card-${index}`}
          variant="text"
          sx={{
            borderRadius: "8px",
            height: "15px",
            width: index === 2 ? "50%" : "75%",
            transform: "unset",
          }}
        />
      ))}
    </Box>
    <Skeleton
      variant="rectangular"
      sx={{
        height: "100%",
        width: "70%",
        borderRadius: "8px",
      }}
    />
  </Box>
);

const CardPlaceholder = () => (
  <Box
    sx={{
      height: "110px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 12px",
      boxShadow:
        "0px 12px 45px rgba(44, 34, 76, 0.05), 0px 8px 20px rgba(44, 34, 76, 0.05)",
    }}
  >
    <Box
      sx={{
        width: "30%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Typography
        sx={{
          borderRadius: "8px",
          height: "15px",
          width: "100%",
          transform: "unset",
        }}
      >
        No results
      </Typography>
    </Box>
    <Box
      sx={{
        height: "100%",
        width: "70%",
        borderRadius: "8px",
      }}
    />
  </Box>
);
const Index = () => {
  const { salesAssistantHeaderData, salesAssistantHeaderDataLoading, daysToShow } =
    useSalesAssistant();

  const chartColors = ["#6A3FB2", "#0062CC", "#1AD075", "#F89A43"];
  return (
    <Grid container spacing={2} marginBottom="10px">
      {!salesAssistantHeaderDataLoading && salesAssistantHeaderData
        ? salesAssistantHeaderData?.map((card, index) => {
          const formattedValue = (value: number) =>
            (card.type === "currency" ? "$" : "") +
            convertNumberToShortFormat(value) +
            (card.type === "percentage" ? "%" : "");

          const past_value = daysToShow == '7d' ? card.value_7d_prev : card.value_28d_prev
          const [width, setWidth] = useState(0);
          const cardRef = useRef(null);

          useEffect(() => {
            // Create ResizeObserver to track width changes
            const observer = new ResizeObserver((entries) => {
              for (const entry of entries) {
                setWidth(entry.contentRect.width);
              }
            });

            // Start observing the container element
            if (cardRef.current) {
              observer.observe(cardRef.current);
            }

            // Cleanup observer on unmount
            return () => observer.disconnect();
          }, []);

          return (
            <Grid item xs={3} key={`sales_assistant_head_card-${index}`}>
              <Card
                sx={{
                  flex: 1,
                  height: "110px",
                  filter:
                    "drop-shadow(0px 12px 45px rgba(44, 34, 76, 0.05)) drop-shadow(0px 8px 20px rgba(44, 34, 76, 0.05))",
                  border: "0 none",
                  borderRadius: "8px",
                  padding: "5px 12px",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      width: "fit-content",
                      zIndex: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        textWrap: "nowrap",
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "24px",
                      }}
                    >
                      {card.title}
                    </Typography>
                    <Tooltip
                      title={
                        KPITootipText[
                        card?.title as keyof typeof KPITootipText
                        ] || ""
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            zIndex: 2,
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "inline-flex",
                          cursor: "pointer",
                        }}
                      >
                        <InfoIconV2 />
                      </Box>
                    </Tooltip>
                  </Box>
                  <Stack direction='row'>
                    <Stack direction='column' sx={{ minWidth: '110px' }}>
                      <Typography
                        variant="caption"
                        color={theme.palette.text.secondary}
                      >
                        {getLastNWeeksRange(card.categories, daysToShow == '7d' ? 1 : 4)}
                      </Typography>
                      <Box
                        sx={{
                          marginTop: "14px",
                        }}
                      >
                        <NumberSection
                          number={daysToShow == '7d' ? card.value_7d : card.value_28d}
                          change={daysToShow == '7d' ? card.change_7d : card.change_28d}
                          percentage={
                            card.type === "percentage" ? (daysToShow == '7d' ? card.change_7d : card.change_28d) : undefined
                          }
                          unit={
                            card.type === "percentage"
                              ? "%"
                              : card.type === "currency"
                                ? "$"
                                : undefined
                          }
                        />
                      </Box>
                      <Typography
                        variant="caption"
                        fontSize={10}
                        color={theme.palette.text.secondary}
                        component={'div'}
                      >
                        {
                          card.type !== "percentage"
                            ? `${card.type == 'currency' ? '$' : ''}${formatCurrencyOnlyNumber(past_value)}`
                            : `${past_value.toFixed(2)}%`
                        } {getLastNWeeksRange(card.categories, daysToShow == '7d' ? 1 : 4, daysToShow == '7d' ? 1 : 4)}
                      </Typography>
                    </Stack>
                    {card.graph_data && card.graph_data.length > 0 ? (
                      <Box
                        id="sa-chart"
                        sx={{
                          transform: "translate(0, -30%)",
                          width: '100%'
                        }}
                        ref={cardRef}
                      >
                        {width > 90 && <Chart
                          height="120px"
                          options={{
                            chart: {
                              id: card.title,
                              toolbar: {
                                show: false,
                                tools: {
                                  download: false,
                                },
                              },
                            },
                            grid: {
                              xaxis: {
                                lines: {
                                  show: false,
                                },
                              },
                              yaxis: {
                                lines: {
                                  show: false,
                                },
                              },
                            },
                            stroke: {
                              width: 2,
                              curve: "smooth",
                              colors: [
                                ...chartColors.slice(index),
                                ...chartColors.slice(0, index),
                              ],
                            },
                            legend: {
                              show: card.graph_data.length > 1,
                              position: "top",
                              horizontalAlign: "right",
                              floating: true,
                              offsetY: 30,
                              markers: {
                                size: 5,
                                offsetX: -3,
                                fillColors: [
                                  ...chartColors.slice(index),
                                  ...chartColors.slice(0, index),
                                ],
                              },
                            },
                            tooltip: {
                              enabled: true,
                              shared: true,
                              intersect: false,
                              followCursor: false,
                              x: {
                                show: true,
                              },
                              custom: ({ series, dataPointIndex, ...args }: any) => {
                                return renderToString(
                                  <CustomTooltip
                                    series={series.map((serie: any) => {
                                      return serie.map((value: any) =>
                                        formattedValue(value)
                                      )
                                    }
                                    )}
                                    labels={card.categories}
                                    colors={[
                                      ...chartColors.slice(index),
                                      ...chartColors.slice(0, index),
                                    ]}
                                    dataPointIndex={dataPointIndex}
                                    arrow={false}
                                  />
                                );
                              },
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            fill: {
                              colors: [
                                ...chartColors.slice(index),
                                ...chartColors.slice(0, index),
                              ],
                              type: "gradient",
                              gradient: {
                                shade: "light",
                                type: "vertical",
                                opacityFrom: 0.5,
                                opacityTo: 0,
                              },
                            },
                            xaxis: {
                              tickAmount: Math.floor(width / 50),
                              categories: card.categories,
                              labels: {
                                formatter: function (value) {
                                  return dayjs(parseInt(value) / 1000).format(
                                    "MMM"
                                  );
                                },
                                rotate: 0,
                                style: {
                                  colors: "#75717B",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                },
                              },
                              axisTicks: {
                                show: false,
                              },
                              tooltip: {
                                enabled: false,
                              }
                            },
                            yaxis: {
                              labels: {
                                show: false,
                              },
                            },
                          }}
                          series={card.graph_data.map((data, index) => ({
                            name: (new Date().getFullYear() - index).toString(),
                            data: [...data].reverse(),
                          }))}
                          type="area"

                        />}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#75717B",
                          }}
                        >
                          No data available
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          );
        })
        : Array.from({ length: 4 }).map((_, index) => (
          <Grid item xs={3} key={`sales_assistant_head_card-${index}`}>
            {salesAssistantHeaderDataLoading ? (
              <CardSkeleton />
            ) : (
              <CardPlaceholder />
            )}
          </Grid>
        ))}
    </Grid >
  );
};

export default Index;
