import { Box, Tooltip, Typography } from "@mui/material";
import { TableColumn } from "../../types";
import { FC } from "react";
import theme from "../../../../themes/light";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { InfoIconV2 } from "../../../../assets";

interface TableColumnsProps {
  columns: TableColumn[];
  sortBy: any;
  setSortBy: any;
}

const TableColumns: FC<TableColumnsProps> = ({
  columns,
  sortBy,
  setSortBy,
}) => {
  const columnsToDisplay = columns.filter(
    (column) => !column.name.toLowerCase().includes("growth")
  );

  const handleClick = (column: string) => {
    if (sortBy.column === column) {
      setSortBy({
        column,
        order: sortBy.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortBy({ column, order: "asc" });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0 20px",
        width: "100%",
      }}
    >
      {columnsToDisplay.map((column, index) => (
        <Box
          key={column.name}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: `${100 / columnsToDisplay.length}%`,
            gap: "8px",
            padding: "0 20px",
          }}
        >
          <Typography
            variant="caption"
            color={theme.palette.text.primary}
            sx={{
              fontWeight: sortBy.column === column.name ? 500 : 400,
              cursor: column.name !== "Product" ? "pointer" : "default",
            }}
            onClick={() => {
              if (column.name !== "Product") {
                handleClick(column.name);
              }
            }}
          >
            {column.name}
          </Typography>
          {column.description && (
            <Tooltip
              title={column.description}
              placement="top"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    zIndex: 2,
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: "inline-flex",
                  cursor: "default",
                }}
              >
                <InfoIconV2 />
              </Box>
            </Tooltip>
          )}
          <Box
            sx={{
              width: "10px",
              visibility: sortBy.column === column.name ? "visible" : "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {sortBy.order === "asc" ? (
              <ArrowUpwardIcon />
            ) : (
              <ArrowDownwardIcon />
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default TableColumns;
