import { Stack } from "@mui/material";
import MainArea from "./MainArea";
import Tabs from "./Tabs.tsx";
import { useState } from "react";
import { tabsConfig } from "./enums.ts";

const Index = () => {
  const [activeTab, setActiveTab] = useState(tabsConfig[0].value_name); // TODO: Change to 0
  return (
    <Stack direction="row" height="100%" overflow="hidden">
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <MainArea activeTab={activeTab} />
    </Stack>
  );
};

export default Index;
