/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Features, Space } from "../SpacesV2/types";
import { useSpaceQuery, useSpacesQuery } from "../SpacesV2/useSpaces";
import {
  useBrandStrategyData,
  useFilterOptionsData,
  useMarketBreakdownData,
  usePricingDistributionData,
  useProductsReviewData,
  useRevenueShareData,
  useSalesPerformanceData,
} from "./api";
import {
  AssortmentFilters,
  BrandStrategyAssortment,
  FilterOptions,
  MarketBreakdownAssortment,
  PeriodFilter,
  PricingDistributionAssortment,
  ProductsReviewAssortment,
  RevenueShareAssortment,
  SalesPerformanceAssortment,
  SelectedView,
} from "./types";
import { compareFilters, isAttributeNumerical } from "./utils";

const AssortmentIntelligenceState = {
  // Space
  spaceId: "",
  spaceList: undefined as Space[] | undefined,
  selectedSpace: undefined as Space | undefined,
  isSpaceExtractingFeatures: false,
  // Attributes
  spaceAttributes: [] as Features[],
  spaceAttributesLoading: false,
  // Filters
  filterOptions: {
    period: [
      PeriodFilter.last_30_days,
      PeriodFilter.last_90_days,
      PeriodFilter.year_to_date,
    ] as PeriodFilter[],
    brand: [] as string[],
    price_range: [] as string[],
    individual_attributes: {},
  } as FilterOptions | undefined,
  filterOptionsLoading: false,
  filters: {
    period: PeriodFilter.last_30_days,
    brand: [] as string[],
    price_range: [] as string[],
    individual_attributes: {} as Record<string, string[]>,
  } as AssortmentFilters,
  setFilters: (filters: AssortmentFilters) => {},
  currentFilters: {} as AssortmentFilters,
  setCurrentFilters: (filters: AssortmentFilters) => {},
  allowApplyFilters: false,
  // Views
  selectedView: SelectedView.MarketBreakdown,
  setSelectedView: (view: SelectedView) => {},
  // Market Breakdown
  marketBreakdownData: undefined as MarketBreakdownAssortment | undefined,
  marketBreakdownLoading: false,
  marketBreakdownAttribute: "" as string,
  setMarketBreakdownAttribute: (attribute: string) => {},
  // Brand Strategy
  brandStrategyData: undefined as BrandStrategyAssortment | undefined,
  brandStrategyLoading: false,
  // Sales Performance
  salesPerformanceData: undefined as SalesPerformanceAssortment | undefined,
  salesPerformanceLoading: false,
  // Products Review
  productsReviewData: undefined as ProductsReviewAssortment | undefined,
  productsReviewLoading: false,
  // Pricing Strategy
  pricingDistributionData: undefined as
    | PricingDistributionAssortment
    | undefined,
  pricingDistributionLoading: false,
  revenueShareData: undefined as RevenueShareAssortment | undefined,
  revenueShareLoading: false,
  pricingStrategyAttribute: undefined as string | undefined,
  setPricingStrategyAttribute: (attribute: string) => {},
};

export const AssortmentIntelligenceContext = createContext(
  AssortmentIntelligenceState
);

export const useAssortmentIntelligence = () =>
  useContext(AssortmentIntelligenceContext);

const AssortmentIntelligenceProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  // Space
  const { data: spaceList = [] } = useSpacesQuery();
  const spaceId = location.search.startsWith("?")
    ? location.search.slice(1)
    : "";

  const {
    data: selectedSpace,
    isLoading: isLoadingSpace,
    isFetching: isFetchingSpace,
    refetch: refetchSpace,
  } = useSpaceQuery(spaceId);

  const isSpaceExtractingFeatures = useMemo(
    () => selectedSpace?.status === "extracting attributes",
    [selectedSpace]
  );

  useEffect(() => {
    if (isSpaceExtractingFeatures) {
      const interval = setInterval(() => {
        refetchSpace();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [isSpaceExtractingFeatures]);

  // Attributes
  const spaceAttributes = useMemo(() => {
    return selectedSpace?.metadata?.attributes || [];
  }, [selectedSpace]);

  const [marketBreakdownAttribute, setMarketBreakdownAttribute] =
    useState<string>("");
  const [pricingStrategyAttribute, setPricingStrategyAttribute] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (spaceAttributes.length) {
      const stringAttribute = spaceAttributes.find(
        (attr) => attr.type === "string"
      );
      setMarketBreakdownAttribute(
        stringAttribute
          ? stringAttribute.attribute
          : spaceAttributes[0].attribute
      );
      const numericalAttribute = spaceAttributes.find((attr) =>
        isAttributeNumerical(attr)
      );
      setPricingStrategyAttribute(
        numericalAttribute ? numericalAttribute.attribute : undefined
      );
    }
  }, [spaceAttributes]);

  // Filters
  const [filters, setFilters] = useState<AssortmentFilters>({
    period: PeriodFilter.last_30_days,
    brand: [],
    price_range: [],
    individual_attributes: {},
  });

  const filtersPayload = useMemo(() => {
    return {
      period: filters.period,
      brand: filters.brand?.length ? filters.brand : undefined,
      price_range: filters.price_range?.length
        ? filters.price_range
        : undefined,
      individual_attributes:
        filters.individual_attributes &&
        Object.keys(filters.individual_attributes).reduce(
          (acc, key) => {
            if (filters.individual_attributes?.[key].length) {
              acc[key] = filters.individual_attributes[key];
            }
            return acc;
          },
          {} as Record<string, string[]>
        ),
    };
  }, [filters]);

  const [currentFilters, setCurrentFilters] = useState(filters);

  const allowApplyFilters = useMemo(() => {
    return !compareFilters(currentFilters, filters);
  }, [currentFilters, filters]);

  const {
    data: filterOptions,
    isLoading: isLoadingFilterOptions,
    isFetching: isFetchingFilterOptions,
  } = useFilterOptionsData({ space_id: spaceId, filters: filtersPayload });

  // Views
  const [selectedView, setSelectedView] = useState<SelectedView>(
    SelectedView.MarketBreakdown
  );

  // Data
  const {
    data: marketBreakdownData,
    isLoading: isLoadingMarketBreakdown,
    isFetching: isFetchingMarketBreakdown,
  } = useMarketBreakdownData({
    space_id: spaceId,
    attribute: marketBreakdownAttribute,
    filters: filtersPayload,
  });

  const {
    data: brandStrategyData,
    isLoading: isLoadingBrandStrategy,
    isFetching: isFetchingBrandStrategy,
  } = useBrandStrategyData({
    space_id: spaceId,
    filters: filtersPayload,
  });

  const {
    data: salesPerformanceData,
    isLoading: isLoadingSalesPerformance,
    isFetching: isFetchingSalesPerformance,
  } = useSalesPerformanceData({
    space_id: spaceId,
    filters: filtersPayload,
  });

  const {
    data: productsReviewData,
    isLoading: isLoadingProductsReview,
    isFetching: isFetchingProductsReview,
  } = useProductsReviewData({
    space_id: spaceId,
    filters: filtersPayload,
  });

  const {
    data: pricingDistributionData,
    isLoading: isLoadingPricingDistribution,
    isFetching: isFetchingPricingDistribution,
  } = usePricingDistributionData({
    space_id: spaceId,
    filters: filtersPayload,
  });

  const {
    data: revenueShareData,
    isLoading: isLoadingRevenueShare,
    isFetching: isFetchingRevenueShare,
  } = useRevenueShareData({
    space_id: spaceId,
    attribute: pricingStrategyAttribute,
    filters: filtersPayload,
  });

  const value = {
    // Space
    spaceId,
    spaceList,
    selectedSpace,
    isSpaceExtractingFeatures,
    // Attributes
    spaceAttributes,
    spaceAttributesLoading: isLoadingSpace || isFetchingSpace,
    // Filters
    filterOptions,
    filterOptionsLoading: isLoadingFilterOptions || isFetchingFilterOptions,
    filters,
    setFilters,
    currentFilters,
    setCurrentFilters,
    allowApplyFilters,
    // Views
    selectedView,
    setSelectedView,
    // Market Breakdown
    marketBreakdownData,
    marketBreakdownLoading:
      isLoadingMarketBreakdown || isFetchingMarketBreakdown,
    marketBreakdownAttribute,
    setMarketBreakdownAttribute,
    // Brand Strategy
    brandStrategyData,
    brandStrategyLoading: isLoadingBrandStrategy || isFetchingBrandStrategy,
    // Sales Performance
    salesPerformanceData,
    salesPerformanceLoading:
      isLoadingSalesPerformance || isFetchingSalesPerformance,
    // Products Review
    productsReviewData,
    productsReviewLoading: isLoadingProductsReview || isFetchingProductsReview,
    // Pricing Strategy
    pricingDistributionData,
    pricingDistributionLoading:
      isLoadingPricingDistribution || isFetchingPricingDistribution,
    revenueShareData,
    revenueShareLoading: isLoadingRevenueShare || isFetchingRevenueShare,
    pricingStrategyAttribute,
    setPricingStrategyAttribute,
  };

  return (
    <AssortmentIntelligenceContext.Provider value={value}>
      {children}
    </AssortmentIntelligenceContext.Provider>
  );
};

export default AssortmentIntelligenceProvider;
