import { MenuItem, Select, Stack, Typography } from "@mui/material";
import theme from "../../themes/light";
import { useAssortmentIntelligence } from "./AssortmentIntelligenceProvider";
import Table from "./components/Table/Table";
import TableSkeleton from "./components/Table/TableSkeleton";

const MarketBreakdown = () => {
  const {
    marketBreakdownAttribute,
    setMarketBreakdownAttribute,
    spaceAttributes,
    marketBreakdownData,
    marketBreakdownLoading,
  } = useAssortmentIntelligence();
  const { table_schema, table_data } = marketBreakdownData ?? {};

  return (
    <Stack
      sx={{
        width: "100%",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        padding: "16px",
        gap: "8px",
      }}
    >
      <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "4px" }}>
        <Typography
          variant="body4"
          sx={{
            fontSize: "14px",
            color: theme.palette.grey[400],
          }}
        >
          {`Leading `}
        </Typography>
        <Select
          variant="standard"
          value={marketBreakdownAttribute}
          onChange={(e) => setMarketBreakdownAttribute(e.target.value)}
          sx={{
            fontSize: "14px",
            color: theme.palette.text.primary,
          }}
        >
          {spaceAttributes.map((attribute) => (
            <MenuItem
              key={`market-breakdown-${attribute.attribute}`}
              value={attribute.attribute}
            >
              {attribute.attribute}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      {table_data && table_schema && !marketBreakdownLoading ? (
        <Table
          data={table_data}
          schema={table_schema}
          defaultSortBy="Market Share"
        />
      ) : (
        <TableSkeleton columnsNumber={6} rowsNumber={10} />
      )}
    </Stack>
  );
};

export default MarketBreakdown;
