import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Tooltip,
} from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { Search } from "../../../../components/SearchInput/Search";
import { ClearIcon } from "@mui/x-date-pickers";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import theme from "../../../../themes/light";

const FilterDropdownSearch = ({
  name,
  filter,
  setFilter,
}: {
  name: string;
  filter: string;
  setFilter: (value: string) => void;
}) => {
  return (
    <ListSubheader
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <Search
        fullWidth
        placeholder={`Search ${name}...`}
        onKeyDown={(e) => {
          if (e.key !== "Escape") {
            e.stopPropagation();
          }
        }}
        InputProps={{
          value: filter,
          onChange: (e) => setFilter(e.currentTarget.value),
        }}
      />
    </ListSubheader>
  );
};

interface FilterDropdownProps {
  label: string;
  allowSearch?: boolean;
  isMultiSelect?: boolean;
  value: any | any[];
  valueFormatter?: (value: any) => string;
  setValue: (value: any | any[]) => void;
  options: any[] | undefined;
  optionsLoading?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
}

const FilterDropdown: FC<FilterDropdownProps> = ({
  label,
  allowSearch = true,
  isMultiSelect = true,
  value,
  valueFormatter = (value) => value,
  setValue,
  options,
  optionsLoading,
  icon,
  disabled,
}) => {
  const [filter, setFilter] = useState("");
  const [optionsToDisplay, setOptionsToDisplay] = useState<any[] | undefined>(
    []
  );

  useEffect(() => {
    setOptionsToDisplay(
      options?.filter((option) =>
        valueFormatter(option)?.toLowerCase().includes(filter.toLowerCase())
      )
    );
  }, [options, filter]);

  const sortSelectedOptions = () => {
    setTimeout(
      () =>
        setOptionsToDisplay((prevOptions) => {
          if (!prevOptions) return [];
          return [
            ...prevOptions
              .filter((option) => value.includes(option))
              .sort((a, b) => {
                const aFormatted = valueFormatter(a).toLowerCase();
                const bFormatted = valueFormatter(b).toLowerCase();
                return aFormatted.localeCompare(bFormatted);
              }),
            ...prevOptions.filter((option) => !value.includes(option)),
          ];
        }),
      300
    );
  };

  const renderValue = (selected: any) => (
    <Stack
      sx={{
        position: "relative",
        width: "100%",
      }}
    >
      <Box
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "flex",
          gap: "4px",
        }}
      >
        <Chip
          label={
            !isMultiSelect || selected.length === 1
              ? valueFormatter(value)
              : `${selected.length} selected`
          }
          size="small"
          sx={{
            borderRadius: "4px",
            padding: "4px",
            textTransform: "capitalize",
            fontWeight: 500,
            color: theme.palette.primary.dark,
            backgroundColor: "#EEE5FE",
          }}
        />
      </Box>
    </Stack>
  );

  return (
    <FormControl sx={{ width: "200px" }}>
      <InputLabel
        id={`filter-${label}-label`}
        sx={{
          "&.MuiFormLabel-root": {
            color: theme.palette.text.primary,
            fontSize: "14px",
            fontWeight: 500,
            marginTop:
              (isMultiSelect && value.length > 0) || (!isMultiSelect && value)
                ? "5px"
                : "0px",
            backgroundColor: "white",
            "&.Mui-focused": {
              color: theme.palette.primary.main,
              marginTop: "5px",
            },
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        onClose={() => {
          if (isMultiSelect) sortSelectedOptions();
        }}
        multiple={isMultiSelect}
        value={value}
        inputProps={{
          icon: icon,
        }}
        renderValue={renderValue}
        onChange={(e) => setValue(e.target.value)}
        // onOpen={fetchOptions}
        disabled={disabled}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            sx: {
              "& .MuiList-root": {
                paddingTop: allowSearch ? "0px" : "8px",
              },
            },
            style: {
              minHeight: optionsLoading ? 100 : undefined,
              maxHeight: 300,
              minWidth: "200px",
              padding: 0,
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
              borderRadius: "4px",
              display: optionsLoading ? "flex" : "block",
              flexDirection: "column",
              justifyContent: "center",
            },
          },
        }}
        sx={{
          "&.MuiOutlinedInput-root": {
            borderRadius: "8px",
            marginTop: "5px",
            "&.Mui-focused fieldset": {
              borderWidth: "1px",
            },
            "& fieldset": {
              borderColor:
                (isMultiSelect && value.length > 0) || (!isMultiSelect && value)
                  ? theme.palette.primary.main
                  : undefined,
            },
          },
        }}
        IconComponent={
          isMultiSelect && value.length > 0
            ? () => (
                <Tooltip
                  title="Clear"
                  placement="top"
                  arrow
                  sx={{ background: "white" }}
                >
                  <IconButton
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      setValue([]);
                    }}
                    aria-label="clear selection"
                    sx={{
                      right: 10,
                      position: "absolute",
                      background: "white",
                      padding: 0.5,
                      "& .MuiSvgIcon-root": {
                        fontSize: "small",
                      },
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              )
            : undefined
        }
      >
        {optionsLoading && (
          <LoadingSpinner
            size={40}
            color={theme.palette.primary.main}
            borderWidth={2}
          />
        )}
        {!optionsLoading && allowSearch && (
          <FilterDropdownSearch
            name={label}
            filter={filter}
            setFilter={setFilter}
          />
        )}
        {!optionsLoading &&
          optionsToDisplay?.map((option) => (
            <MenuItem
              key={`menu-item-${valueFormatter(option)}`}
              value={option}
              sx={{ height: 35 }}
            >
              {isMultiSelect && <Checkbox checked={value.includes(option)} />}
              <ListItemText primary={valueFormatter(option)} />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default FilterDropdown;
