/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Features, Space } from "../types";
import { attributesEqual } from "./utils";
import { useSpace } from "../SpaceProvider";

const FeaturesExtractionState = {
  space: undefined as Space | undefined,
  spaceDataRefetch: () => {},
  spaceDataLoading: false as boolean,
  isSpaceExtractingFeatures: false as boolean,
  spaceAttributes: [] as Features[],
  currentAttributes: undefined as Features[] | undefined,
  setCurrentAttributes: (attributes: Features[]) => {},
  changesMade: false as boolean,
};

export const FeaturesExtractionContext = createContext(FeaturesExtractionState);

export const useFeaturesExtraction = () =>
  useContext(FeaturesExtractionContext);

const FeaturesExtractionProvider = ({ children }: { children: ReactNode }) => {
  // Space
  const { space, spaceDataLoading, spaceDataRefetch, isSpaceExtractingFeatures } = useSpace();

  // Attributes
  const spaceAttributes: Features[] = useMemo(() => {
    if (!space?.metadata?.attributes) {
      return [];
    }
    return space?.metadata?.attributes.map((attr) => {
      return {
        attribute: attr.attribute,
        type: attr.type,
        description: attr.description,
        closed_list: attr.closed_list,
        accepted_values: attr.accepted_values,
      };
    });
  }, [space?.metadata?.attributes]);

  const [currentAttributes, setCurrentAttributes] = useState<
    Features[] | undefined
  >(undefined);

  useEffect(() => {
    setCurrentAttributes(JSON.parse(JSON.stringify(spaceAttributes)));
  }, [spaceAttributes]);

  const changesMade = useMemo(
    () => !attributesEqual(currentAttributes || [], spaceAttributes),
    [currentAttributes, spaceAttributes]
  );

  const value = {
    space,
    spaceDataRefetch,
    spaceDataLoading,
    isSpaceExtractingFeatures,
    spaceAttributes,
    currentAttributes,
    setCurrentAttributes,
    changesMade,
  };

  return (
    <FeaturesExtractionContext.Provider value={value}>
      {children}
    </FeaturesExtractionContext.Provider>
  );
};

export default FeaturesExtractionProvider;
