import { Box } from "@mui/material";
import { FC } from "react";
import RowValueFormatter from "./RowValueFormatter";
import { useAssortmentIntelligence } from "../../AssortmentIntelligenceProvider";
import { TableColumn } from "../../types";
import { getFilterColumns } from "../../utils";
import ProductDetails from "./ProductDetails";
import AttributeProducts from "./AttributeProducts";
import { parseJsonRecursive } from "../../../ResearchAssistant/utils";

interface TableRowsProps {
  data: any[];
  schema: TableColumn[];
  allowFilters?: boolean;
}

const TableRows: FC<TableRowsProps> = ({
  data,
  schema,
  allowFilters = true,
}) => {
  const { currentFilters, setCurrentFilters, spaceAttributes } =
    useAssortmentIntelligence();

  const columnsToMap = schema.filter(
    (column) => !column.name.toLowerCase().includes("growth")
  );

  const filterColumns = getFilterColumns(schema, spaceAttributes);

  const handleFilterColumnClick = (value: any, filterColumn?: string) => {
    if (filterColumn === "brand") {
      if (!currentFilters.brand?.includes(value)) {
        setCurrentFilters({
          ...currentFilters,
          brand: [...(currentFilters.brand || []), value],
        });
      }
    } else if (filterColumn) {
      const individualAttribute = spaceAttributes.find(
        (attribute) => attribute.attribute.toLowerCase() === filterColumn
      );
      if (individualAttribute) {
        const currentValues =
          currentFilters.individual_attributes?.[
            individualAttribute.attribute
          ] || [];
        if (!currentValues.includes(value)) {
          setCurrentFilters({
            ...currentFilters,
            individual_attributes: {
              ...currentFilters.individual_attributes,
              [individualAttribute.attribute]: [...currentValues, value],
            },
          });
        }
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
        overflowX: "hidden",
        padding: "0 20px",
      }}
    >
      {data.map((item, index) => (
        <Box
          key={`list-item-${index}`}
          className="table-list-item"
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            backgroundColor: "white",
            border: "1px solid #E1E6ED",
            minHeight: "50px",
            "&:hover": { backgroundColor: "#F4F3F8" },
          }}
        >
          {columnsToMap.map((column: any, index: number) => {
            return (
              <Box
                key={`list-item-column-${index}`}
                sx={{
                  width: `${100 / columnsToMap.length}%`,
                  display: "flex",
                  alignItems: "center",
                  padding: "0 20px",
                }}
              >
                {column.name === "Product" ? (
                  <ProductDetails asin={item["ASIN"]} image={item["Image"]} />
                ) : column.name === "Products" ? (
                  <AttributeProducts
                    total={parseJsonRecursive(item["Products"]).total}
                    products={parseJsonRecursive(item["Products"]).products}
                  />
                ) : (
                  <RowValueFormatter
                    value={item[column.name]}
                    growthValue={item[`${column.name} Growth`]}
                    heatLevel={item[`${column.name}_heatLevel`]}
                    type={
                      column.name.toLowerCase().includes("rating")
                        ? "rating"
                        : column.type
                    }
                    onClick={
                      allowFilters &&
                      Object.keys(filterColumns).includes(column.name)
                        ? () =>
                            handleFilterColumnClick(
                              item[column.name],
                              filterColumns[column.name]
                            )
                        : undefined
                    }
                  />
                )}
              </Box>
            );
          })}
        </Box>
      ))}
    </Box>
  );
};

export default TableRows;
