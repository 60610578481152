import { FC } from "react";
import { Box, Stack, Pagination } from "@mui/material";
import theme from "../../themes/light";

interface PaginationProps {
  totalRows: number;
  currentPage: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  type: string;
  customLimit?: number;
}

const CustomPagination: FC<PaginationProps> = ({
  totalRows,
  currentPage,
  handleChange,
  type,
  customLimit,
}) => {
  const limit = customLimit ? customLimit : type === "ProductList" ? 100 : 10;

  return (
    <Box
      sx={{ position: "absolute", bottom: 0, zIndex: 10 }}
      className="custom-pagination"
    >
      <Box
        sx={{
          borderBottom: `1px solid ${theme.palette.borders.lines}`,
          height: "50px",
          gap: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "sticky",
          background: theme.palette.backgrounds.secondary,
        }}
      >
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(totalRows / limit)}
            page={currentPage}
            onChange={handleChange}
            sx={{
              "& .MuiPaginationItem-root.Mui-selected": {
                background: "#EEE5FE",
              },
              "& .MuiSvgIcon-root": {
                color: "#2C224C",
              },
            }}
            shape="rounded"
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default CustomPagination;
