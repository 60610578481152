import { FC } from "react";
import { Features } from "../../types";
import { Box, Stack } from "@mui/material";
import { FeatureField, TableColumn } from "../types";
import FeatureFieldFormatter from "./FeatureFieldFormatter";

interface TableRowsProps {
  data: Features[];
  schema: TableColumn[];
  showDeleteButton: (index: number) => void;
}

const TableRows: FC<TableRowsProps> = ({ data, schema, showDeleteButton }) => {
  return (
    <Stack
      sx={{
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {data.map((item, index) => (
        <Stack
          key={`list-item-${index}`}
          id={`list-item-${index}`}
          sx={{
            flexDirection: "row",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
            padding: "0 10px",
            minHeight: "60px",
            backgroundColor: index % 2 === 0 ? "#F9F9F9" : "white",
          }}
          onMouseEnter={() => showDeleteButton(index)}
        >
          {schema.map((column: TableColumn, colIdx: number) => (
            <Box
              key={`list-item-column-${colIdx}`}
              sx={{
                height: "100%",
                display: "flex",
                padding: "0 10px",
                // width: column.width || `${100 / schema.length}%`,
                flex: column.flex || 1,
                gap: "8px",
              }}
            >
              <FeatureFieldFormatter
                field={column.field as FeatureField}
                item={item}
                index={index}
              />
            </Box>
          ))}
        </Stack>
      ))}
    </Stack>
  );
};

export default TableRows;
