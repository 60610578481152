import { FC } from "react";
import { convertNumberToShortFormat } from "../../../ResearchAssistant/utils";
import { Box, Checkbox, Stack, Typography } from "@mui/material";
import theme from "../../../../themes/light";
import { Icon } from "../../../../components/Icon";

interface RowValueFormatterProps {
  value: any;
  growthValue?: any;
  heatLevel?: number;
  type: "string" | "number" | "currency" | "percentage" | "rating" | "boolean";
  onClick?: () => void;
}

const RowValueFormatter: FC<RowValueFormatterProps> = ({
  value,
  growthValue,
  heatLevel,
  type,
  onClick,
}) => {
  if (value === null || value === undefined) {
    return;
  }

  const heatLevels = ["white", "#d3e9f8", "#91c9ee", "#65b3e7", "#389de0"];

  const onClickSx = {
    color: onClick ? "primary.main" : "inherit",
    textDecoration: onClick ? "underline" : "none",
    cursor: onClick ? "pointer" : "default",
    "&:hover": {
      textDecoration: onClick ? "underline" : "none",
      color: onClick ? "primary.dark" : "inherit",
    },
  };

  const formattedValue = convertNumberToShortFormat(
    value,
    type === "percentage"
  );
  const formattedGrowthValue = growthValue
    ? convertNumberToShortFormat(growthValue, type === "percentage")
    : null;

  return (
    <Box
      sx={
        heatLevel
          ? {
              backgroundColor: heatLevels[heatLevel - 1],
              borderRadius: "8px",
              padding: "8px",
              width: "60%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }
          : {}
      }
    >
      {type === "boolean" ? (
        <Checkbox checked={value} disabled />
      ) : type === "string" ? (
        <Typography variant="body5" sx={onClickSx} onClick={onClick}>
          {value}
        </Typography>
      ) : type === "rating" ? (
        <Box
          sx={{
            display: "flex",
            gap: "4px",
            alignItems: "center",
          }}
        >
          {value && <Typography variant="body5">⭐️ {value}</Typography>}
        </Box>
      ) : !formattedGrowthValue ? (
        <Typography variant="body5" onClick={onClick} sx={onClickSx}>
          {`${type === "currency" ? "$" : ""}${formattedValue}`}
        </Typography>
      ) : (
        <Stack direction="row" spacing={1}>
          <Typography variant="body5">
            {`${type === "currency" ? "$" : ""}${formattedValue}`}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              alignItems: "center",
            }}
          >
            {growthValue > 0 ? (
              <Icon src="sales/trend-up.svg" height="16px" width="16px" />
            ) : growthValue < 0 ? (
              <Icon src="sales/trend-down.svg" height="16px" width="16px" />
            ) : null}
            <Typography
              variant="body5"
              color={
                growthValue > 0
                  ? theme.palette.text.success
                  : theme.palette.error.main
              }
            >
              {`${type === "currency" ? "$" : ""}${formattedGrowthValue}`}
            </Typography>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default RowValueFormatter;
