import { FC, useMemo } from "react";
import { TableColumn } from "../../../AssortmentIntelligence/types";
import { Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { convertNumberToShortFormat } from "../../../ResearchAssistant/utils";
import { renderToString } from "react-dom/server";
import ChartTooltip from "../../../../components/ChartTooltip/ChartTooltip";

const lineColors = ["#237fd2", "#8c60b1"];
const areaColors = ["#c1d8f2", "#dbcee7"];

interface DrawerLineChartProps {
  data: Record<string, any>[];
  schema: TableColumn[];
}

const DrawerLineChart: FC<DrawerLineChartProps> = ({ data, schema }) => {
  const dataColumn = schema
    .map((column) => column.name)
    .filter(
      (column) => !["Date", "Time Frame", "Calendar Week"].includes(column)
    )[0];

  const dataColumnType = schema.find(
    (column) => column.name === dataColumn
  )?.type;

  const series = useMemo(
    () => [
      {
        name: "Previous Year",
        data: data
          .filter((item) => item["Time Frame"] === "Previous Year")
          .map((item) => item[dataColumn]),
        type: "area",
      },
      {
        name: "Current Year",
        data: data
          .filter((item) => item["Time Frame"] === "Current Year")
          .map((item) => item[dataColumn]),
        type: "area",
      },
    ],
    [data, dataColumn]
  );

  const showLabel = (seriesIdx: number, dataIdx: number) =>
    (seriesIdx === 0 && dataIdx % 4 === 0) ||
    (seriesIdx === 1 && dataIdx % 4 === 2);

  const options = {
    series: series,
    chart: {
      type: "area",
      toolbar: { show: false, tools: { download: false } },
      zoom: {
        allowMouseWheelZoom: false,
      },
    },
    plotOptions: { area: { stacked: true } },
    dataLabels: {
      enabled: true,
      offsetY: -7.5,
      style: {
        fontSize: "12px",
        fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 600,
        colors: ["black"],
      },
      background: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        opacity: 1,
        color: "#fff",
      },
      formatter: (val: number, opts?: any) =>
        showLabel(opts.seriesIndex, opts.dataPointIndex)
          ? convertNumberToShortFormat(val, dataColumnType === "percentage")
          : "",
    },
    stroke: {
      curve: "smooth",
      width: 2,
      show: true,
      colors: lineColors,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "left",
      fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
      fontSize: "12px",
      fontWeight: 500,
      markers: { fillColors: lineColors, offsetX: -3 },
    },
    markers: {
      size: 0,
      colors: lineColors,
      strokeWidth: 1,
      hover: {
        size: 5,
      },
    },
    fill: {
      colors: areaColors,
    },
    xaxis: {
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    axisBorder: {
      show: false,
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      custom: ({ seriesIndex, dataPointIndex }: any) =>
        renderToString(
          <ChartTooltip
            header={new Date(data[dataPointIndex]["Date"]).toLocaleDateString(
              "en-US",
              {
                month: "short",
                day: "numeric",
                year: "numeric",
              }
            )}
            data={[
              {
                name: "Calendar Week",
                value: data[dataPointIndex]["Calendar Week"],
              },
              {
                name: "Time Frame",
                value: data[dataPointIndex]["Time Frame"],
              },
              {
                name: dataColumn,
                value: convertNumberToShortFormat(
                  data[dataPointIndex][dataColumn],
                  dataColumnType === "percentage"
                ),
                color: lineColors[seriesIndex],
              },
            ]}
            brightenColors={true}
          />
        ),
    },
  };

  return (
    <Box
      id="drawer-line-chart"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        width: "auto",
      }}
    >
      <ReactApexChart
        //@ts-ignore
        options={options}
        series={series}
        type={"line"}
        height={400}
        width={930}
      />
    </Box>
  );
};

export default DrawerLineChart;
