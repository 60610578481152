import { Box, Skeleton, Stack } from "@mui/material";

const EstimatedRevenueSkeleton = () => {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "16px 60px",
        gap: "40px",
      }}
    >
      <Stack
        sx={{
          gap: "4px",
        }}
      >
        {Array.from({ length: 10 }).map((_, index) => (
          <Stack
            key={`skeleton-row-${index}`}
            sx={{
              flexDirection: "row",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <Skeleton
              key={`skeleton-circle-${index}`}
              variant="circular"
              sx={{
                borderRadius: "50%",
                height: "15px",
                width: "15px",
                transform: "unset",
              }}
            />
            <Skeleton variant="text" width="100px" />
          </Stack>
        ))}
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "60%",
          position: "relative",
        }}
      >
        <Skeleton
          variant="circular"
          sx={{
            borderRadius: "50%",
            height: "340px",
            width: "340px",
            transform: "unset",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            borderRadius: "50%",
            height: "230px",
            width: "230px",
            backgroundColor: "white",
          }}
        ></Box>
      </Box>
    </Stack>
  );
};

export default EstimatedRevenueSkeleton;
