import { Stack } from "@mui/material";
import AssortmentIntelligenceProvider, {
  useAssortmentIntelligence,
} from "./AssortmentIntelligenceProvider";
import Header from "./Header";
import MainSection from "./MainSection";
import EmptyState from "./EmptyState";
import ExtractingFeaturesLoader from "../SpacesV2/FeatureExtraction/ExtractingFeaturesLoader";

const Index = () => {
  const {
    spaceId,
    spaceAttributes,
    spaceAttributesLoading,
    isSpaceExtractingFeatures,
  } = useAssortmentIntelligence();

  return (
    <Stack
      sx={{
        height: "100vh",
        width: `calc(100% - 50px)`,
      }}
    >
      <Header />
      {spaceId && isSpaceExtractingFeatures ? (
        <ExtractingFeaturesLoader />
      ) : spaceId && (spaceAttributes.length > 0 || spaceAttributesLoading) ? (
        <MainSection />
      ) : (
        <EmptyState />
      )}
    </Stack>
  );
};

export default () => (
  <AssortmentIntelligenceProvider>
    <Index />
  </AssortmentIntelligenceProvider>
);
