import { FC } from "react";
import "./ChartTooltip.css";
import { getRgbFromHex } from "../../pages/AssortmentIntelligence/utils";

interface ChartTooltipDataItem {
  name: string;
  value: any;
  color?: string;
}

interface ChartTooltipProps {
  data: ChartTooltipDataItem[];
  brightenColors?: boolean;
  header?: string;
  headerColor?: string;
}

const ChartTooltip: FC<ChartTooltipProps> = ({
  data,
  brightenColors,
  header,
  headerColor,
}) => {
  return (
    <div className="chart-tooltip-container">
      {header && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          {headerColor && (
            <span
              className="chart-tooltip-header-color-circle"
              style={{ backgroundColor: headerColor }}
            ></span>
          )}
          <div className="chart-tooltip-header">{header}</div>
        </div>
      )}
      <div className="chart-tooltip-content">
        <div className="chart-tooltip-keys">
          {data.map(({ name }) => (
            <div className="chart-tooltip-key">{name}:</div>
          ))}
        </div>
        <div className="chart-tooltip-values">
          {data.map(({ value, color }, _idx) => (
            <div
              className="chart-tooltip-value"
              style={
                color
                  ? {
                      backgroundColor: brightenColors
                        ? `rgba(${getRgbFromHex(color)}, 0.8)`
                        : color,
                      padding: "0 5px",
                      borderRadius: "5px",
                      textAlign: "center",
                      fontWeight: "500",
                      width: "100%",
                    }
                  : {}
              }
            >
              {value}
            </div>
          ))}
        </div>
      </div>
      <div className="tooltip-arrow"></div>
    </div>
  );
};

export default ChartTooltip;
