import { DrawerQuestion } from "../../types.ts";
import { Box, Stack, Typography } from "@mui/material";
import { Icon } from "../../../../components/Icon.tsx";
import { palette } from "../../../../themes/light.ts";
import { useMemo } from "react";
import { useSalesAssistant } from "../../SalesAssistantProvider.tsx";
import EmbeddedQuestion from "../EmbeddedQuestion.tsx";
import { useSalesAssistantInsight } from "../../api.ts";

const Question = ({ question }: { question: DrawerQuestion }) => {
  const { selectedProduct, spaceId, daysToShow } = useSalesAssistant();

  const iconUrl = useMemo(() => {
    if (question.closeable && question.default_closed) {
      return "insights/collapse.svg";
    } else if (question.closeable) {
      return "insights/addIcon.svg";
    } else {
      return "insights/expand.svg";
    }
  }, [question]);

  const {
    data: questionData,
    isLoading: questionDataLoading,
    isFetching: questionDataFetching,
  } = useSalesAssistantInsight({
    asin: selectedProduct?.title,
    insight_id: question.question_id,
    period_view: daysToShow,
    space_id: spaceId,
  });

  return (
    <Stack gap="16px">
      <Stack direction="row" alignItems="center" gap="16px">
        <Box
          sx={{
            minWidth: "20px",
            display: "flex",

            alignItems: "center",
          }}
        >
          <Icon src={iconUrl} width="20px" height="20px" />
        </Box>
        <Typography variant="body2" color={palette.text.primary}>
          {question.title}
        </Typography>
      </Stack>
      <EmbeddedQuestion
        questionData={questionData}
        questionLoading={
          !selectedProduct || questionDataLoading || questionDataFetching
        }
      />
    </Stack>
  );
};

export default Question;
