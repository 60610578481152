import { FC, useMemo } from "react";
import { TableColumn } from "../../../AssortmentIntelligence/types";
import { Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { convertNumberToShortFormat } from "../../../ResearchAssistant/utils";
import { renderToString } from "react-dom/server";
import ChartTooltip from "../../../../components/ChartTooltip/ChartTooltip";

const columnsColor = "#237fd2";
const lineColors = ["#88bf4d", "#ef8c8c", "#69c8c8"];

interface DrawerColumnsChartProps {
  data: Record<string, any>[];
  schema: TableColumn[];
}

const DrawerColumnsChart: FC<DrawerColumnsChartProps> = ({ data, schema }) => {
  const dataColumns = schema.filter(
    (column) => !["Date"].includes(column.name)
  );

  const series = useMemo(
    () =>
      dataColumns.map((column, index) => ({
        name: column.name,
        data: data.map((row) => row[column.name]),
        type: index === 0 ? "column" : "line",
        color: index === 0 ? columnsColor : lineColors[index - 1],
      })),
    [data, dataColumns]
  );

  const seriesTypes = series.map(
    (s) => schema.find((c) => c.name === s.name)?.type
  );

  const showLabel = (seriesIdx: number, dataIdx: number) =>
    dataIdx % series.length === seriesIdx;

  const labelFormatter = (
    val: number,
    seriesIndex?: any
  ) => `${seriesTypes[seriesIndex] === "currency" ? "$" : ""}
          ${convertNumberToShortFormat(
            val,
            seriesTypes[seriesIndex] === "percentage"
          )}`;

  const options = {
    series: series,
    chart: {
      type: "bar",
      toolbar: { show: false, tools: { download: false } },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 7,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: [columnsColor, ...lineColors],
      },
      background: {
        enabled: true,
        dropShadow: { enabled: true, top: 1, left: 1, blur: 1, opacity: 0.5 },
      },
      formatter: (val: number, opts?: any) =>
        labelFormatter(val, opts.seriesIndex),
    },
    stroke: {
      curve: "smooth",
      width: series.map((s, i) => (i === 0 ? 0 : 2)),
      show: true,
      colors: lineColors,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      showForZeroSeries: true,
      position: "top",
      horizontalAlign: "left",
      fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
      fontSize: "12px",
      fontWeight: 500,
      markers: { fillColors: [columnsColor, ...lineColors], offsetX: -3 },
    },
    markers: {
      size: 0,
      colors: lineColors,
      strokeWidth: 1,
      hover: {
        size: 5,
      },
    },
    xaxis: {
      categories: data.map((row) => row["Date"]),
      tickAmount: 5,
      position: "bottom",
      labels: {
        style: {
          fontSize: "14px",
        },
        rotate: 0,
        formatter: (val: string) =>
          new Date(val).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          }),
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      axisBorder: { show: false },
      tooltip: { enabled: false },
    },
    yaxis: Array.from({ length: series.length }, () => ({
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    })),
    tooltip: {
      enabled: true,
      followCursor: true,
      custom: ({ seriesIndex, dataPointIndex }: any) =>
        renderToString(
          <ChartTooltip
            data={series.map((s, i) => ({
              name: s.name,
              value: labelFormatter(data[dataPointIndex][s.name], i),
              color: s.color,
            }))}
            brightenColors={true}
          />
        ),
    },
  };

  return (
    <Box
      id="drawer-columns-chart"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        width: "auto",
      }}
    >
      <ReactApexChart
        //@ts-ignore
        options={options}
        series={series}
        type={"bar"}
        height={400}
        width={930}
      />
    </Box>
  );
};

export default DrawerColumnsChart;
