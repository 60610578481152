import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { convertNumberToShortFormat } from "../../../ResearchAssistant/utils";
import { renderToString } from "react-dom/server";
import { useAssortmentIntelligence } from "../../AssortmentIntelligenceProvider";
import ChartTooltip from "../../../../components/ChartTooltip/ChartTooltip";

interface EstimatedRevenueProps {
  data: Record<string, any>[];
}

const EstimatedRevenue: FC<EstimatedRevenueProps> = ({ data }) => {
  const { currentFilters, setCurrentFilters } = useAssortmentIntelligence();

  const handleBrandClick = (brand: string) => {
    const currentBrands = currentFilters.brand || [];
    if (!currentBrands.includes(brand)) {
      setCurrentFilters({
        ...currentFilters,
        brand: [...currentBrands, brand],
      });
    }
  };

  const colors = [
    "#509ee2",
    "#86bcec",
    "#88be4d",
    "#a6d07b",
    "#8a5eb0",
    "#a989c5",
    "#f7c4c4",
    "#ee8c8b",
    "#ed8534",
    "#f9d35c",
    "grey",
  ];

  const processedData = useMemo(() => {
    // Sort data by revenue in descending order
    const sortedData = [...data].sort(
      (a, b) => b["Estimated Revenue"] - a["Estimated Revenue"]
    );

    // Split into top 10 and others
    const top10 = sortedData.slice(0, 10);
    const others = sortedData.slice(10);

    // If there are more than 10 brands, add an "Others" category
    if (others.length > 0) {
      const othersRevenue = others.reduce(
        (sum, item) => sum + item["Estimated Revenue"],
        0
      );
      const othersShare = others.reduce(
        (sum, item) => sum + item["Estimated Revenue Share"],
        0
      );

      top10.push({
        Brand: `${others.length} more brands`,
        "Estimated Revenue": othersRevenue,
        "Estimated Revenue Share": othersShare,
      });
    }

    return top10;
  }, [data]);

  const series = useMemo(
    () => processedData.map((item) => item["Estimated Revenue"]),
    [processedData]
  );

  const options = useMemo(() => {
    return {
      chart: {
        type: "donut",
        events: {
          legendClick: (chart: any, seriesIndex?: number) => {
            if (
              seriesIndex !== undefined &&
              seriesIndex < data.length &&
              !processedData[seriesIndex]["Brand"].includes("more brands")
            ) {
              handleBrandClick(processedData[seriesIndex]["Brand"]);
            }
          },
        },
      },
      dataLabels: { enabled: false },
      plotOptions: { pie: { expandOnClick: false } },
      colors: colors.slice(0, processedData.length),
      labels: processedData.map((item) => item["Brand"]),
      legend: {
        show: true,
        showForSingleSeries: true,
        position: "left",
        fontSize: "14px",
        markers: { offsetX: -5, onItemHover: { highlightDataSeries: true } },
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        y: {
          formatter: (value: number) => `$${convertNumberToShortFormat(value)}`,
        },
        custom: ({ seriesIndex }: any) =>
          renderToString(
            <ChartTooltip
              header={processedData[seriesIndex]["Brand"]}
              headerColor={colors[seriesIndex]}
              data={[
                {
                  name: "Revenue",
                  value: `$${convertNumberToShortFormat(
                    processedData[seriesIndex]["Estimated Revenue"]
                  )}`,
                },
                {
                  name: "Revenue Share",
                  value: `${convertNumberToShortFormat(
                    processedData[seriesIndex]["Estimated Revenue Share"],
                    true
                  )}`,
                },
              ]}
            />
          ),
      },
    };
  }, [processedData, colors, data]);

  return (
    <Box
      id="estimated-revenue-chart"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "auto",
        width: "auto",
      }}
    >
      <ReactApexChart
        // @ts-ignore
        options={options}
        series={series}
        type="donut"
        height={550}
        width={550}
      />
    </Box>
  );
};

export default EstimatedRevenue;
