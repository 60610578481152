import apiRequest, { APIError } from "../../services/apiRequest.ts";
import { useInfiniteQuery, useQuery } from "react-query";
import {
  EComRequest,
  Features,
  Keywords,
  KeywordsData,
  Space,
  SpaceASINsResponse,
  SpaceExportResponse,
} from "./types.ts";
import {
  GetPerfectContentResponse,
  GetProductsResponse,
} from "../../services/ecommerce/useEcommerceProducts.ts";
import { ProductsFilterConfiguration } from "../../types/ecommerce.ts";
import { GetAllProductsResponse } from "./CatalogView.tsx";

function getSpaces(): Promise<Space[]> {
  return apiRequest<Space[]>("v1/spaces", "GET");
}

function getSpace(id?: string): Promise<Space> | unknown {
  return id
    ? apiRequest<Space[]>(`v1/spaces/${id}`, "GET")
    : Promise.resolve({});
}

function getSpaceKeywords(
  id?: string,
  limit?: number
): Promise<{ total: number; data: Keywords[] }> | unknown {
  const params: any = { limit };
  return id
    ? apiRequest<Keywords[]>(`v1/keywords/table/${id}`, "GET", params)
    : Promise.resolve({});
}

export const getSpaceASINs = async ({
  spaceId,
  filterByLabel,
  filterByText,
  filterByContains,
  sortBy,
  ...pageParams
}: {
  spaceId?: string;
  filterByLabel?: string;
  filterByText?: string;
  filterByContains?: boolean;
  sortBy?: string;
  pageParams?: any;
}) => {
  const params: any = {};
  if (pageParams?.pageParams?.pageParam) {
    params.offset = pageParams?.pageParams?.pageParam;
  }
  if (filterByLabel) {
    params.product_label_filter = filterByLabel;
  }
  if (filterByText) {
    params.text_filter = filterByText;
    params.not_contain = !filterByContains;
  }
  if (sortBy) {
    params.sort_by = sortBy;
  }
  return spaceId
    ? apiRequest<SpaceASINsResponse>(
        `v1/spaces/${spaceId}/asins`,
        "GET",
        params
      )
    : Promise.resolve([]);
};

const getAllSpacesAsins = async ({
  spaceId,
  filterByLabel,
  filterByText,
  filterByContains,
}: {
  spaceId?: string;
  filterByLabel?: string;
  filterByText?: string;
  filterByContains?: boolean;
  sortBy?: string;
  pageParams?: any;
}) => {
  const params: any = {};
  if (filterByLabel) {
    params.product_label_filter = filterByLabel;
  }
  if (filterByText) {
    params.text_filter = filterByText;
    params.not_contain = !filterByContains;
  }
  return spaceId
    ? apiRequest<string[]>(`v1/spaces/${spaceId}/only_asins`, "GET", params)
    : Promise.resolve([]);
};

export function useSpacesQuery() {
  return useQuery<Space[], APIError>(["useSpaces"], () => getSpaces(), {
    staleTime: Infinity,
  });
}

export function useAllSpaceAsinsQuery({
  spaceId,
  filterByText,
  filterByLabel,
  filterByContains,
}: {
  spaceId?: string;
  filterByLabel?: string;
  filterByText?: string;
  filterByContains?: boolean;
}) {
  return useQuery<string[], APIError>(
    [
      "useAllSpaceAsins",
      spaceId,
      filterByLabel,
      filterByText,
      filterByContains,
    ],
    () =>
      getAllSpacesAsins({
        spaceId,
        filterByLabel,
        filterByText,
        filterByContains,
      }),
    {
      staleTime: Infinity,
      enabled: !!spaceId,
    }
  );
}

export function useSpaceQuery(id?: string) {
  return useQuery<Space, APIError>(
    ["useSpace", id],
    () => getSpace(id) as Space,
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );
}

export function useSpaceKeywordsQuery({
  spaceId,
  limit = 100,
}: {
  spaceId: string;
  limit?: number;
}) {
  return useQuery<{ total: number; data: Keywords[] }, APIError>(
    ["useSpaceKeywords", spaceId],
    () =>
      getSpaceKeywords(spaceId, limit) as { total: number; data: Keywords[] },
    {
      staleTime: Infinity,
      enabled: !!spaceId,
    }
  );
}

export function useSpaceASINsQuery({
  spaceId,
  filterByText,
  filterByLabel,
  filterByContains,
  sortBy,
}: {
  spaceId?: string;
  filterByLabel?: string;
  filterByText?: string;
  filterByContains?: boolean;
  sortBy?: string;
}) {
  return useInfiniteQuery({
    enabled: !!spaceId,
    refetchOnWindowFocus: false,
    queryKey: [
      "useSpaceASINs",
      spaceId,
      filterByLabel,
      filterByText,
      filterByContains,
      sortBy,
    ],
    queryFn: (pageParams) =>
      getSpaceASINs({
        spaceId,
        filterByLabel,
        filterByText,
        filterByContains,
        sortBy,
        pageParams,
      }),
    getNextPageParam: (_lastPage, pages) => {
      let asins: any[] = [];
      pages.forEach((page: any) => {
        asins = asins.concat(page.asins);
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return asins.length < pages?.[0]?.total ? asins.length : false;
    },
  });
}

export const exportSpaceApi = async ({
  spaceId,
  filterByLabel,
  filterByText,
  filterByContains,
  sortBy,
}: {
  spaceId: string;
  filterByLabel?: string;
  filterByText?: string;
  filterByContains?: boolean;
  sortBy?: string;
}): Promise<SpaceExportResponse> => {
  const params: any = { with_impressions: true };
  if (filterByLabel) {
    params.product_label_filter = filterByLabel;
  }
  if (filterByText) {
    params.text_filter = filterByText;
    params.not_contain = !filterByContains;
  }
  if (sortBy) {
    params.sort_by = sortBy;
  }
  return await apiRequest<SpaceExportResponse>(
    `v1/spaces/${spaceId}/export`,
    "GET",
    params
  );
};

export const createSpace = ({
  name,
  asins,
  autoEnrich,
}: {
  name: string;
  asins: string[];
  autoEnrich: boolean;
}): Promise<Space> =>
  apiRequest<Space>("v1/spaces", "POST", undefined, {
    asins,
    name,
    autoEnrich,
  });
export const deleteSpaceApi = (spaceId: string): Promise<Space> =>
  apiRequest<Space>(`v1/spaces/${spaceId}`, "DELETE");

export const runAttributeExtractionApi = ({
  spaceId,
  features,
}: {
  spaceId: string;
  features: Features[];
}): Promise<Space> => {
  return apiRequest<Space>(
    `v1/spaces/${spaceId}/run/attribute_extraction`,
    "POST",
    undefined,
    features
  );
};

export const updateSpaceTitleApi = ({
  spaceId,
  newName,
}: {
  spaceId: string;
  newName: string;
}): Promise<Space> => {
  return apiRequest<Space>(`v1/spaces/${spaceId}`, "PUT", undefined, {
    name: newName,
  });
};

export const addAsinsAPI = ({
  spaceId,
  asins,
}: {
  spaceId: string;
  asins: string[];
}): Promise<Space> => {
  return apiRequest<Space>(`v1/spaces/${spaceId}/asins`, "POST", undefined, {
    asins,
    reason: "manual add",
  });
};
export const removeAsinsAPI = ({
  spaceId,
  asins,
}: {
  spaceId: string;
  asins: string[];
}): Promise<Space> => {
  return apiRequest<Space>(
    `v1/spaces/${spaceId}/asins`,
    "DELETE",
    undefined,
    asins
  );
};

export const moveSuggestedToSpaceAPI = ({
  spaceId,
  asins,
}: {
  spaceId: string;
  asins: string[];
}): Promise<Space> => {
  return apiRequest<Space>(
    `v1/spaces/${spaceId}/approve_suggested_asins`,
    "POST",
    undefined,
    asins
  );
};
export const rejectSuggestedAPI = ({
  spaceId,
  asins,
}: {
  spaceId: string;
  asins: string[];
}): Promise<Space> => {
  return apiRequest<Space>(
    `v1/spaces/${spaceId}/reject_suggested_asins`,
    "DELETE",
    undefined,
    { asins, reason: "deleted by user" }
  );
};

export const getKeywordsListFromAsins = ({
  spaceId,
  asins,
  queryParams,
}: {
  spaceId?: string;
  asins: string[];
  queryParams?: any;
}): Promise<KeywordsData> => {
  if (!spaceId) {
    return Promise.resolve({ data: [], total: 0, status: "failed" });
  }
  return apiRequest<KeywordsData>(
    `v1/keywords/table/${spaceId}/asins`,
    "POST",
    queryParams,
    asins
  );
};

export const getEcommerceProducts = (
  offset: number,
  limit: number,
  filters?: ProductsFilterConfiguration,
  sortBy?: string
): Promise<GetProductsResponse> =>
  apiRequest<GetProductsResponse>(
    "v1/onboarding/ecommerce/products/search",
    "POST",
    {
      offset,
      limit,
      sort_by: sortBy,
    },
    filters
  );

export const getAllEcommerceProducts = (
  filters?: ProductsFilterConfiguration
): Promise<GetAllProductsResponse> =>
  apiRequest<GetAllProductsResponse>(
    "v1/onboarding/ecommerce/products/asins_list",
    "POST",
    {},
    filters
  );

export const useEComProductsQuery = (eComRequest: EComRequest) =>
  useQuery(
    ["useECommerce", eComRequest],
    () =>
      getEcommerceProducts(
        eComRequest.offset,
        eComRequest.limit,
        eComRequest.filter
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

export const getPerfectContent = ({
  spaceId,
  filterByLabel,
  filterByText,
  filterByContains,
  offset,
}: {
  spaceId?: string;
  filterByLabel?: string;
  filterByText?: string;
  filterByContains?: boolean;
  offset?: number;
}): Promise<GetPerfectContentResponse> => {
  const params: any = { offset };
  if (filterByLabel) {
    params.product_label_filter = filterByLabel;
  }
  if (filterByText) {
    params.text_filter = filterByText;
    params.not_contain = !filterByContains;
  }
  return apiRequest<GetPerfectContentResponse>(
    `v1/perfect_content/${spaceId}`,
    "GET",
    params
  );
};

export const usePerfectContentQuery = ({
  spaceId,
  filterByLabel,
  filterByText,
  filterByContains,
  offset,
}: {
  spaceId?: string;
  filterByLabel?: string;
  filterByText?: string;
  filterByContains?: boolean;
  offset?: number;
}) =>
  useQuery(
    [
      "usePerfectContent",
      spaceId,
      filterByLabel,
      filterByText,
      filterByContains,
      offset,
    ],
    () =>
      getPerfectContent({
        spaceId,
        filterByLabel,
        filterByText,
        filterByContains,
        offset,
      }),
    {
      enabled: !!spaceId,
    }
  );
