import { Box, Typography } from "@mui/material";
import theme from "../../../themes/light";
import noogataloader from "/spaces/noogata-loader.gif";

const ExtractingFeaturesLoader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={noogataloader} alt="Loading" />
      <Typography sx={theme.typography.h3_v2} textAlign="center">
        Extracting Your Features!
      </Typography>
      <Typography
        sx={{ ...theme.typography.h4_v2, fontWeight: 400 }}
        textAlign="center"
      >
        We will let you know once your features are ready.
      </Typography>
    </Box>
  );
};

export default ExtractingFeaturesLoader;
