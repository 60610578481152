import CloseIcon from "@mui/icons-material/Close";
import theme from "../../../themes/light.ts";
import {
  Box,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { salesColumnWidths } from "../../SalesAssistant/constants.ts";
import InfoSection from "./InfoSection.tsx";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import NumberSection from "../Components/NumberSection.tsx";
import ProductImage from "../Components/ProductRow/ProductImage.tsx";
import FiltersAndSort from "../Header/FiltersAndSort.tsx";

const DrawerTitle = ({ onDrawerClose }: { onDrawerClose: () => void }) => {
  const { selectedProduct } = useSalesAssistant();

  const LabeledNumberSection = ({
    label,
    numberSection,
  }: {
    label: string;
    numberSection?: JSX.Element;
  }) => (
    <Stack sx={{ flexDirection: "column", gap: "5px" }}>
      <Typography variant="caption" color="text.secondary">
        {label}:
      </Typography>
      {numberSection ?? <Skeleton variant="text" width={100} />}
    </Stack>
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={4}
      paddingBottom="20px"
      borderBottom={`1px solid ${theme.palette.borders.lines}`}
      justifyContent="space-between"
    >
      <IconButton
        onClick={onDrawerClose}
        sx={{ background: "none", p: 2.5 / 8 }}
      >
        <CloseIcon
          sx={{ fontSize: "22px" }}
          htmlColor={theme.palette.icons.secondary}
        />
      </IconButton>
      <Stack
        direction="row"
        gap="16px"
        sx={{ display: "flex", gap: "16px", alignItems: "center" }}
        width={salesColumnWidths.info}
      >
        <Box minWidth={salesColumnWidths.image}>
          {selectedProduct ? (
            <ProductImage url={selectedProduct?.image_url} size={"medium"} />
          ) : (
            <Skeleton
              variant="rectangular"
              width={100}
              height={100}
              sx={{
                trainsform: "unset",
                borderRadius: "8px",
              }}
            />
          )}
        </Box>
        <Box>
          <InfoSection type="product" data={selectedProduct} />
        </Box>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        border={`1px solid ${theme.palette.borders.lines}`}
        borderRadius="8px"
        padding="8px 12px"
        gap="24px"
        minWidth={"50%"}
      >
        <LabeledNumberSection
          label="Revenue"
          numberSection={
            selectedProduct && (
              <NumberSection
                unit="$"
                number={selectedProduct?.revenue}
                percentage={selectedProduct?.revenue_percentage}
                change={selectedProduct?.revenue_change}
                title="Revenue"
              />
            ) 
          }
        />
        <Divider orientation="vertical" flexItem />
        <LabeledNumberSection
          label="Units Sold"
          numberSection={
            selectedProduct && (
              <NumberSection
                number={selectedProduct?.units_sold}
                percentage={selectedProduct?.units_sold_percentage}
                change={selectedProduct?.units_sold_change}
                title="Units Sold"
              />
            ) 
          }
        />
        <Divider orientation="vertical" flexItem />
        <LabeledNumberSection
          label="Average Price"
          numberSection={
            selectedProduct && (
              <NumberSection
                unit="%"
                number={selectedProduct?.conversion}
                percentage={selectedProduct?.conversion_percentage}
                change={selectedProduct?.conversion_change}
                title="Conversion"
              />
            ) 
          }
        />
        <Divider orientation="vertical" flexItem />
        <LabeledNumberSection
          label="Margin"
          numberSection={
            selectedProduct && (
              <NumberSection
                number={selectedProduct?.glance_views}
                percentage={selectedProduct?.glance_views_percentage}
                change={selectedProduct?.glance_views_change}
                title="Glance Views"
              />
            ) 
          }
        />
      </Stack>
      <FiltersAndSort showRefresh={false} />
    </Stack>
  );
};

export default DrawerTitle;
