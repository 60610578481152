import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useAskLLM } from "./hooks.ts";
import { useSalesAssistant } from "../../SalesAssistantProvider.tsx";
import ReactMarkdown from "react-markdown";

const ChatComponent = () => {
  const [messages, setMessages] = useState([
    { id: 1, text: 'Hello! How can I help you today?', sender: 'bot' }
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [questionInput, setQuestionInput] = useState('');
  const { selectedAsin } = useSalesAssistant();

  const { data, isLoading, refetch } = useAskLLM(
    { asin: selectedAsin, user_question: questionInput },
  );

  // Add useEffect to handle data arrival
  useEffect(() => {
    if (data) {
      const botResponse = {
        id: messages.length + 2,
        text: data || 'No response from server',
        sender: 'bot'
      };
      setMessages(prevMessages => [...prevMessages, botResponse]);
    }
  }, [data]);

  const handleSendMessage = () => {
    if (inputMessage.trim() === '') return;

    const userMessage = {
      id: messages.length + 1,
      text: inputMessage,
      sender: 'user'
    };
    setMessages(prevMessages => [...prevMessages, userMessage]);

    setQuestionInput(inputMessage);
    refetch();

    setInputMessage('');
  };

  // Rest of the component remains the same as in the previous example

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {/* Header */}
      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
        Chat Assistant
      </Typography>

      {/* Chat Area */}
      <Paper
        elevation={3}
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          p: 2,
        }}
      >
        <List
          sx={{
            flex: 1,
            overflowY: "auto",
            p: 2,
            backgroundColor: "#fafafa",
            borderRadius: 1,
          }}
        >
          {messages.map((message) => (
            <ListItem
              key={message.id}
              sx={{
                justifyContent:
                  message.sender === "user" ? "flex-end" : "flex-start",
              }}
            >
              <Paper
                sx={{
                  p: 1.5,
                  maxWidth: "70%",
                  backgroundColor:
                    message.sender === "user" ? "primary.light" : "grey.200",
                }}
              >
                <ListItemText
                  primary={<ReactMarkdown>{message.text}</ReactMarkdown>}
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                />
              </Paper>
            </ListItem>
          ))}

          {isLoading && (
            <ListItem sx={{ justifyContent: "flex-start" }}>
              <Paper sx={{ p: 1.5, backgroundColor: "grey.200" }}>
                <Typography variant="body2">Typing...</Typography>
              </Paper>
            </ListItem>
          )}
        </List>
      </Paper>

      {/* Input Section */}
      <Box
        sx={{
          display: "flex",
          p: 2,
          borderTop: "1px solid",
          borderColor: "divider",
          backgroundColor: "white",
          borderRadius: 1,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type a message"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") handleSendMessage();
          }}
          sx={{ mr: 1 }}
          disabled={isLoading}
        />
        <IconButton
          color="primary"
          onClick={handleSendMessage}
          disabled={isLoading}
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
    //
    // <Box
    //   sx={{
    //     maxWidth: 400,
    //     margin: 'auto',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     height: 500
    //   }}
    // >
    //   <Paper
    //     elevation={3}
    //     sx={{
    //       flex: 1,
    //       display: 'flex',
    //       flexDirection: 'column',
    //       overflow: 'hidden'
    //     }}
    //   >
    //     <Typography
    //       variant="h6"
    //       sx={{
    //         p: 2,
    //         backgroundColor: 'primary.main',
    //         color: 'white'
    //       }}
    //     >
    //       Chat App
    //     </Typography>
    //
    //     <List
    //       sx={{
    //         flex: 1,
    //         overflowY: 'auto',
    //         p: 2
    //       }}
    //     >
    //       {messages.map((message) => (
    //         <ListItem
    //           key={message.id}
    //           sx={{
    //             justifyContent: message.sender === 'user'
    //               ? 'flex-end'
    //               : 'flex-start'
    //           }}
    //         >
    //           <Paper
    //             sx={{
    //               p: 1.5,
    //               maxWidth: '70%',
    //               backgroundColor: message.sender === 'user'
    //                 ? 'primary.light'
    //                 : 'grey.200'
    //             }}
    //           >
    //             <ListItemText
    //               primary={<ReactMarkdown>{message.text}</ReactMarkdown>}
    //               primaryTypographyProps={{
    //                 variant: 'body2'
    //               }}
    //             />
    //           </Paper>
    //         </ListItem>
    //       ))}
    //
    //       {isLoading && (
    //         <ListItem sx={{ justifyContent: 'flex-start' }}>
    //           <Paper sx={{ p: 1.5, backgroundColor: 'grey.200' }}>
    //             <Typography variant="body2">Typing...</Typography>
    //           </Paper>
    //         </ListItem>
    //       )}
    //     </List>
    //
    //     <Box
    //       sx={{
    //         display: 'flex',
    //         p: 2,
    //         borderTop: '1px solid',
    //         borderColor: 'divider'
    //       }}
    //     >
    //       <TextField
    //         fullWidth
    //         variant="outlined"
    //         placeholder="Type a message"
    //         value={inputMessage}
    //         onChange={(e) => setInputMessage(e.target.value)}
    //         onKeyPress={(e) => {
    //           if (e.key === 'Enter') handleSendMessage();
    //         }}
    //         sx={{ mr: 1 }}
    //         disabled={isLoading}
    //       />
    //       <IconButton
    //         color="primary"
    //         onClick={handleSendMessage}
    //         disabled={isLoading}
    //       >
    //         <SendIcon />
    //       </IconButton>
    //     </Box>
    //   </Paper>
    // </Box>
  );
};

export default ChatComponent;
