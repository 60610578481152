import { Dispatch, SetStateAction } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import NumberSection from "../../Components/NumberSection.tsx";
import theme from "../../../../themes/light.ts";
import { RowData } from "../../types.ts";

const Tab = ({
  title,
  unit,
  setActiveTab,
  active,
  value_name,
  selectedProduct,
}: {
  title: string;
  unit?: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  active?: boolean;
  value_name: string;
  selectedProduct?: RowData | undefined;
}) => {
  return (
    <Box
      onClick={() => setActiveTab(value_name)}
      sx={{
        padding: "8px 16px",
        borderRadius: "8px 0 0 8px",
        cursor: "pointer",
        "&.active": { background: theme.palette.backgrounds.secondary },
      }}
      className={active ? "active" : ""}
    >
      <Typography variant="body5">{title}</Typography>
      {selectedProduct ? (
        <NumberSection
          unit={
            unit && ["$", "%"].includes(unit) ? (unit as "$" | "%") : undefined
          }
          number={(selectedProduct[value_name as keyof RowData] ?? 0) as number}
          percentage={
            (selectedProduct[`${value_name}_percentage` as keyof RowData] ??
              0) as number
          }
          change={
            (selectedProduct[`${value_name}_change` as keyof RowData] ??
              0) as number
          }
        />
      ) : (
        <Skeleton variant="text" width="90%" height="30px" />
      )}
    </Box>
  );
};

export default Tab;
