import { Box, Skeleton, Stack } from "@mui/material";
import theme from "../../../../themes/light";
import { FC } from "react";

interface TableSkeletonProps {
  columnsNumber: number;
  rowsNumber: number;
  variant?: "double" | "full" | "half";
}
const TableSkeleton: FC<TableSkeletonProps> = ({
  columnsNumber,
  rowsNumber,
  variant = "full",
}) => {
  return (
    <Stack
      sx={{
        background: theme.palette.backgrounds.secondary,
        padding: variant === "full" ? "12px 30px" : "10px 0 0 0",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        gap: "8px",
        width: "100%",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "0 20px",
          width: "100%",
        }}
      >
        {Array.from({ length: columnsNumber }).map((column, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: `${100 / columnsNumber}%`,
              gap: "8px",
              padding: "0 20px",
            }}
          >
            <Skeleton variant="text" width={100} />
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "auto",
          overflowX: "hidden",
          padding: "0 20px",
        }}
      >
        {Array.from({ length: rowsNumber }).map((row, index) => (
          <Box
            key={`list-item-${index}`}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              backgroundColor: "white",
              border: "1px solid #E1E6ED",
              height: "50px",
              "&:hover": { backgroundColor: "#F4F3F8" },
            }}
          >
            {Array.from({ length: columnsNumber }).map(
              (column: any, index: number) => {
                return (
                  <Box
                    key={`list-item-column-${index}`}
                    sx={{
                      width: `${100 / columnsNumber}%`,
                      display: "flex",
                      alignItems: "center",
                      padding: "0 20px",
                    }}
                  >
                    <Skeleton variant="text" width={100} />
                  </Box>
                );
              }
            )}
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

export default TableSkeleton;
