import { Box, Paper, Skeleton, Typography } from "@mui/material";
import { SalesAssistantInsightOutput } from "../types";
import Table from "../../AssortmentIntelligence/components/Table/Table";
import theme from "../../../themes/light";
import DrawerLineChart from "./DrawerCharts/DrawerLineChart";
import DrawerColumnsChart from "./DrawerCharts/DrawerColumnsChart";

const EmbeddedQuestion = ({
  questionData,
  questionLoading,
}: {
  questionData: SalesAssistantInsightOutput | undefined;
  questionLoading: boolean;
}) => {
  const { table_schema, table_data, graph_type } = questionData ?? {};

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: "5px",
        height: "400px",
        borderRadius: "8px",
        marginLeft: "32px",

        "& .table-list-item": {
          minHeight: "45px",
        },
      }}
    >
      {questionLoading ? (
        <Skeleton
          animation="wave"
          sx={{
            height: "100%",
            width: "100%",
            transform: "unset",
          }}
        />
      ) : !table_schema || !table_data || !graph_type ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
            textAlign: "center",
          }}
        >
          <Box>
            <img src="/insights/noads.svg" alt="noogata" />
          </Box>
          <Box display="flex" gap="4px" flexDirection="column">
            <Typography
              variant="body3"
              color={theme.palette.text.primary}
              sx={{ fontWeight: 600 }}
            >
              No Data found
            </Typography>
          </Box>
        </Box>
      ) : graph_type === "table" ? (
        <Table
          data={table_data}
          schema={table_schema}
          rowsPerPage={6}
          variant={`${table_schema?.length * 20}%`}
          allowFilters={false}
        />
      ) : graph_type === "line" ? (
        <DrawerLineChart data={table_data} schema={table_schema} />
      ) : (
        <DrawerColumnsChart data={table_data} schema={table_schema} />
      )}
    </Paper>
  );
};

export default EmbeddedQuestion;
