import theme from "../../themes/light.ts";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  // ListSubheader,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import EmptyState from "./EmptyState.tsx";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import useOnScreen from "../../hooks/useOnScreen.tsx";
import CustomDotLoader from "../../components/CustomDotLoader.tsx";
import SpaceProductItem from "./SpaceProductItem.tsx";
import { useSpace } from "./SpaceProvider.tsx";
import { Icon } from "../../components/Icon.tsx";
import {
  AscendingIcon,
  CheckboxFilledIcon,
  // CheckboxIcon,
  DescendingIcon,
} from "../../assets/index.tsx";
import { SnackbarKey } from "notistack";

interface AsinsListProps {
  spaceId: string;
  productCountLabel: string;
  asins: any[];
  undoAction: (snackbarId: SnackbarKey, asins: string[]) => ReactNode;
  allSpaceAsins: string[] | undefined;
}

const sortByOptions: { [key: string]: string } = {
  title: "Title",
  categories: "Categories",
  brand: "Brand",
  price: "Price",
  num_of_reviews: "Number of Reviews",
  bought_past_month: "Bought Past Month",
  rating: "Rating",
};

const AsinsList: FC<AsinsListProps> = (props) => {
  const { spaceId, productCountLabel, asins, undoAction, allSpaceAsins } =
    props;
  const {
    asinsList: data,
    isFetchingNextPage,
    isFetchingAsins,
    fetchNextPage,
    hasNextPage,
    sort,
    setSort,
    selectedProducts,
    setSelectedProducts,
    // isSpaceDataLoading,
    openKeywordsTable,
    isArrayEmpty,
    // filterByLabel
  } = useSpace();

  const [open, setOpen] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  const isFetching = isFetchingAsins || isFetchingNextPage;

  useEffect(() => {
    if (!isArrayEmpty && isVisible && !isFetching) {
      fetchNextPage();
    }
  }, [isVisible, isFetching, fetchNextPage, isArrayEmpty]);

  // useEffect(() => {
  //   if (filterByLabel === "competitive_product" || filterByLabel === "my_product") {
  //     const asin = asins?.map((ele) => ele?.asin);
  //     if (asin) {
  //       const common = selectedProducts?.filter((el) => asin?.includes(el)) || [];
  //       setSelectedProducts(common);
  //     }
  //   }
  // }, [filterByLabel, asins]);

  const handleSortFilter = (value: string) => {
    const newSortState = {
      ...sort,
      colId: value,
    };
    setSort(newSortState);
  };

  const handleClickAscending = () => {
    setSort({
      ...sort,
      sort: "desc",
    });
  };

  const handleClickDescending = () => {
    setSort({
      ...sort,
      sort: "asc",
    });
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // const selectedProducts = asins?.map((asin) => asin?.asin);
      setSelectedProducts(allSpaceAsins?.length ? allSpaceAsins : []);
    } else {
      setSelectedProducts([]);
    }
  };

  if (asins.length === 0 && !isFetchingAsins && !isFetchingNextPage) {
    return <EmptyState />;
  }
  return (
    <Box
      sx={{
        flex: 1,
        background: theme.palette.grey[50],
        overflow: "auto",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {(isFetchingAsins || isFetchingNextPage) && <LinearProgress />}
      <Box
        sx={{
          display: "flex",
          padding: "1rem 2rem 0 2rem",
          justifyContent: "space-between",
          height: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            // gap: "0.7rem",
            alignItems: "center",
          }}
        >
          {isFetchingAsins || isFetchingNextPage ? (
            <Skeleton
              animation="wave"
              width="160px"
              sx={{
                height: "15px",
                borderRadius: "8px",
                transform: "unset",
              }}
            />
          ) : (
            <>
              {/* {selectedProducts && selectedProducts?.length > 0 ? (
                <Box
                  sx={{
                    display: "inline-flex",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedProducts([])}
                >
                  <CheckboxFilledIcon />
                </Box>
              ) : (
                <CheckboxIcon />
              )} */}
              <FormControlLabel
                label={productCountLabel}
                control={
                  <Checkbox
                    sx={{
                      mx: 0,
                      display: "inline-flex",
                    }}
                    checked={selectedProducts?.length === allSpaceAsins?.length}
                    onChange={handleSelectAll}
                    indeterminate={
                      selectedProducts &&
                      selectedProducts?.length > 0 &&
                      selectedProducts?.length !== allSpaceAsins?.length
                    }
                    indeterminateIcon={<CheckboxFilledIcon />}
                  />
                }
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "13px",
                    fontWeight: 500,
                    color: theme.palette.grey[500],
                  },
                }}
              />
              {/* <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: 500,
                  color: theme.palette.grey[500],
                }}
              >
                {productCountLabel}
              </Typography> */}
            </>
          )}
        </Box>
        <Stack
          direction="row"
          gap="4px"
          alignItems="center"
          justifyContent="end"
        >
          <Typography variant="body4" color={theme.palette.text.secondary}>
            Sort by:
          </Typography>
          {sort?.sort == "asc" ? (
            <Box
              sx={{
                display: "inline-flex",
                cursor: "pointer",
              }}
              onClick={handleClickAscending}
            >
              <AscendingIcon />
            </Box>
          ) : (
            <Box
              sx={{
                display: "inline-flex",
                cursor: "pointer",
              }}
              onClick={handleClickDescending}
            >
              <DescendingIcon />
            </Box>
          )}
          <Select
            size="small"
            onOpen={() => {
              setOpen((old) => !old);
            }}
            onClose={() => {
              setOpen((old) => !old);
            }}
            renderValue={() => (
              <Stack
                direction="row"
                gap="4px"
                alignItems="center"
                justifyContent="end"
                // minWidth= {90}
              >
                <Typography
                  variant="body2"
                  fontWeight={600}
                  color={
                    open ? theme.palette.text.link : theme.palette.text.primary
                  }
                >
                  {sortByOptions[sort.colId] ?? ""}
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    right: "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {open ? (
                    <Icon src="sales/drop-up.svg" width="16px" height="16px" />
                  ) : (
                    <Icon
                      src="sales/drop-down.svg"
                      width="16px"
                      height="16px"
                    />
                  )}
                </Box>
              </Stack>
            )}
            sx={{
              // minWidth: "190px",
              "& .MuiInputBase-formControl": {
                "&:focus": {
                  backgroundColor: "transparent !important",
                },
              },
              "& .MuiSelect-select": {
                paddingRight: "20px !important",
                paddingLeft: "0",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSvgIcon-root": {
                display: "none",
              },
            }}
            value={sort.colId}
            // onChange={(e) => handleSort(e.target.value)}
          >
            {/* <ListSubheader sx={{ padding: 0, minWidth: 166 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ padding: "8px 8px 8px 16px" }}
              >
                <Typography
                  variant="body2"
                  fontWeight={600}
                  color={theme.palette.secondary.main}
                >
                  Sort by
                </Typography>
              </Stack>
            </ListSubheader> */}
            {Object.entries(sortByOptions).map(([field, displayName]) => (
              <MenuItem
                dense
                value={field}
                onClick={() => handleSortFilter(field)}
                sx={{ marginTop: "5px" }}
              >
                <Typography variant="body3">{displayName}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="start"
        alignItems="stretch"
        sx={{
          flex: 1,
          background: theme.palette.grey[50],
          marginLeft: "16px",
          marginRight: "32px",
          width: "auto",
          paddingTop: "16px",
          paddingBottom: "16px",
        }}
        spacing={2}
      >
        {asins?.map((asin: any) => (
          <SpaceProductItem
            key={asin.id}
            asin={asin}
            spaceId={spaceId}
            undoAction={undoAction}
          />
        ))}
        {hasNextPage && !isArrayEmpty && (
          <Grid
            item
            xs={12}
            sm={4}
            md={openKeywordsTable ? 6 : 3}
            lg={openKeywordsTable ? 4 : 2}
            xl={openKeywordsTable ? 3 : 12 / 7}
          >
            <Box
              ref={ref}
              sx={{
                width: "100%",
                height: "100%",
                boxShadow:
                  "0px 6px 15px 0px rgba(44, 34, 76, 0.05), 0px 8px 32px 0px rgba(44, 34, 76, 0.05)",
                background: theme.palette.background.default,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "16px",
                gap: "8px",
                borderRadius: "12px",
              }}
            >
              <Typography fontWeight="bold">
                Loading More Products...
              </Typography>
              <CustomDotLoader />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AsinsList;
