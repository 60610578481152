import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import { IconButton, Stack, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { generateSalesAssistant } from "../api.ts";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useState } from "react";
import { useUserHasNoogataAdminRole } from "../../../services/auth.ts";
import SelectDropDown from "../../../components/SelectDropDown.tsx";
import { format, subDays } from "date-fns";

const FiltersAndSort = ({ showRefresh = true }: { showRefresh?: boolean }) => {
  const [isFetching, setIsFetching] = useState(false);
  const { daysToShow, setDaysToShow, lastDayOfData } = useSalesAssistant();
  const { enqueueSnackbar } = useSnackbar();
  const generateSalesAssistantMutation = useMutation(() =>
    generateSalesAssistant()
  );
  const isNoogataAdmin = useUserHasNoogataAdminRole();

  const calculateDateRange = (days: string, lastDayOfData?: Date) => {
    if (!lastDayOfData) {
      return ""
    }
    const daysCount = days === "7d" ? 7 : 28;
    const startDate = subDays(lastDayOfData, daysCount - 1);
    return `${format(startDate, "d.M")} - ${format(lastDayOfData, "d.M")}`;
  };

  const daysTooltip = calculateDateRange(daysToShow, lastDayOfData);

  return (
    <Stack direction="row" gap="16px" justifyContent="end" alignItems="center">
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: "#75717B",
            fontSize: "14px",
            lineHeight: "24px",
            fontWeight: 500,
          }}
        >
          Date:
        </Typography>
        <SelectDropDown
          value={daysToShow}
          items={[
            { text: "7 Days", value: "7d" },
            { text: "28 Days", value: "28d" },
          ]}
          onChange={setDaysToShow}
          tooltipTitle={daysTooltip}
        />
      </Stack>
      {isNoogataAdmin && showRefresh && (
        <IconButton
          sx={{
            "@keyframes rotate": {
              "0%": {
                transform: "rotate(0deg)",
              },
              "50%": {
                transform: "rotate(180deg)",
              },
              "100%": {
                transform: "rotate(360deg)",
              },
            },
            animation: isFetching ? "rotate 2s ease infinite" : "",
          }}
          disabled={isFetching}
          aria-label="refresh"
          onClick={() => {
            setIsFetching(true);
            generateSalesAssistantMutation
              .mutateAsync()
              .then(() => {
                enqueueSnackbar("Sales assistant update finished succesfully", {
                  variant: "success",
                });
                setIsFetching(false);
              })
              .catch((e: any) => {
                console.warn(e);
                enqueueSnackbar("Could not fetch sales assistant", {
                  variant: "error",
                });
                setIsFetching(false);
              });
          }}
        >
          <RefreshIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default FiltersAndSort;
