import {Drawer, Stack, Tab} from "@mui/material";
import DrawerTitle from "./DrawerTitle.tsx";
import DrawerTabs from "./DrawerTabs";
import TabsContainer from "@mui/material/Tabs";
import {tabs} from "../../PrefectContentV2/ContentArea/Controller/Tabs.tsx";
import {useState} from "react";
import Summary from "./Summary";
import {useSplitEnabled} from "../../../hooks";
import {Split} from "../../../FeatureFlags/enums.ts";
import AskMe from "./Summary/askMe.tsx";

type ComponentKey = 'summary' | 'diagnostics' | 'ask';

const tabComponents : Record<ComponentKey, () => JSX.Element> = {
      'summary': Summary,
      'diagnostics': DrawerTabs,
      'ask':AskMe
    };


const Index = ({
  isDrawerOpen,
  onDrawerClose,
}: {
  isDrawerOpen: boolean;
  onDrawerClose: () => void;
}) => {
    const summaryEnabled = useSplitEnabled(Split.SALES_ASSISTANT_LLM_SUMMARY)
    const [activeTab, setActiveTab] = useState<ComponentKey>(summaryEnabled ? "summary" : "diagnostics")

    const tabsList =     [
        {label:"Summary", value:"summary",isEnabled :()=>summaryEnabled},
        {label:"Diagnostics", value:"diagnostics",isEnabled :()=> true},
        {label:"Ask me", value:"ask",isEnabled :()=>false}
    ];

    const TabContent= tabComponents[activeTab] || (() => null);
  return (
    <Drawer
      PaperProps={{
        variant: "outlined",
        elevation: 0,
        sx: {
          width: "65vw",
        },
      }}
      anchor="right"
      open={isDrawerOpen}
      onClose={onDrawerClose}
    >
      <Stack p={3} spacing={3.5} sx={{ height: "100%" }}>
        <DrawerTitle onDrawerClose={onDrawerClose} />
        <TabsContainer
          sx={{}}
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
        >
      {
         tabsList.filter(t=>t.isEnabled()).map(({ label, value }: any) => (
        <Tab
          disableRipple
          key={label as string}
          label={label}
          onClick={() => {
            setActiveTab(value);
          }}
          value={value}
        />
      ))}
    </TabsContainer>
          <TabContent />
      </Stack>
    </Drawer>
  );
};

export default Index;
