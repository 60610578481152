import {
  Badge,
  Box,
  Button,
  createTheme,
  Grid,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  MenuItem,
  Skeleton,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from 'moment'
import theme from "../../themes/light.ts";
import { useSpace } from "./SpaceProvider.tsx";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import { MouseEvent, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "react-query";
import { deleteSpaceApi } from "./useSpaces.ts";
import { useSnackbar } from "notistack";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import SpacesHeader from "./SpacesHeader.tsx";
import { SPACES_ROUTE } from "../../pages/Applications/Router";
import { AgGridReact } from "ag-grid-react";
import { Search } from "../../components/SearchInput/Search.tsx";
import { GetSpaceStatsResponse, Space } from "./types.ts";
import { Icon } from "../../components/Icon.tsx";
import useSplitEnabled from "../../hooks/useSplitEnabled.ts";
import { Split } from "../../FeatureFlags/enums.ts";
import apiRequest from "../../services/apiRequest.ts";
import { ColDef } from "ag-grid-enterprise";

interface RowData {
  name: string;
  status: string;
  total_asins?: number;
  created_by: string;
  created_at: Date;
  updated_at?: Date;
  total_suggested?: number;
}

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    height: "36px",
    width: "300px",
    fontSize: "14px",
    background: "white",
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
  },
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#2c224c',
    ...theme.applyStyles('dark', {
      backgroundColor: '#308fe8',
    }),
  },
}));

const getSpacesStats = (
): Promise<GetSpaceStatsResponse> =>
  apiRequest<GetSpaceStatsResponse>(
    "v1/spaces/stats",
    "POST",
    {}
  );


const SpacesList = () => {
  const snackbar = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedSpace, setSelectedSpace] = useState<string | undefined>();
  const {
    spacesList,
    spacesListLoading,
    spacesListFetching,
    spacesListRefetch,

  } = useSpace();

  const [progressBarLoading, setProgressBarLoading,] = useState<boolean | null>(true)
  const [catalogAsinCount, setCatalogAsinCount,] = useState(0)
  const [catalogSpaceAsinCount, setCatalogSpaceAsinCount,] = useState(0)

  useEffect(() => {
    setProgressBarLoading(true)
    getSpacesStats()
      .then(({ catalog_asin_count, catalog_space_asin_count }) => {
        setCatalogAsinCount(catalog_asin_count)
        setCatalogSpaceAsinCount(catalog_space_asin_count)
        setProgressBarLoading(false)
      })
      .catch(e => {
        setProgressBarLoading(null)
      })
  }, [spacesList])

  const open = Boolean(anchorEl);
  const handleClick = (
    event: MouseEvent<HTMLButtonElement>,
    space_id?: string
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedSpace(space_id);
    setAnchorEl(event.currentTarget);
  };
  const deleteMutation = useMutation(() => {
    if (selectedSpace) {
      return deleteSpaceApi(selectedSpace);
    }
    return Promise.reject();
  });

  const handleClose = async (event: MouseEvent<HTMLLIElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedSpace("");

    setAnchorEl(null);
  };

  const [rowData, setRowData] = useState<RowData[] | []>([])

  const getRowData = (spaces?: Space[]) => {
    return spaces?.map((space) => ({
      id: space.id,
      name: space.name,
      status: space.status,
      total_asins: space.total_asins,
      created_by: space.user_email,
      created_at: space.created_at,
      updated_at: space.updated_at,
      total_suggested: space.total_suggested,

    }))
  }

  useEffect(() => {
    const rowData = getRowData(spacesList)
    setRowData(rowData ?? [])
  }, [spacesList])



  const toHumanDate = (dateStr: string) => moment(dateStr).format('MM/DD/YYYY')

  const toSpacePageRouter = (data: any) => {
    // console.log(data);
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "1rem",
          height: "100%",
          position: "relative",
        }}
      >
        <Typography
          // onClick={handleClick}
          sx={{
            color: '#5561f6',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'none', // Optional: Removes underline on hover
            },
            lineHeight: "16px",
            height: "16px",
          }}
        >
          <Link
            to={`${SPACES_ROUTE}/${data.data.id}`}
            style={{ textDecoration: "none", }}

          >
            {data.value}
          </Link>

        </Typography>
      </Box >

    )
  }
  const getActions = (data: any) => {
    return (
      <Box paddingTop="2px">

        <MenuItem
          onClick={(e) => {
            deleteSpaceApi(data.data.id)
              .then(() => {
                snackbar.enqueueSnackbar(
                  "space deleted successfully!",
                  {
                    variant: "success",
                  }
                );
              })
              .catch((e) => {
                snackbar.enqueueSnackbar("Error accrued: " + e, {
                  variant: "success",
                });
              })
              .finally(() => {
                spacesListRefetch();
              });
            handleClose(e);
          }}
          sx={{
            color: theme.palette.error.main,
            display: "flex",
            alignItems: "center",
            paddingLeft: 0,
            width: "24px"
          }}
        >
          <DeleteIcon color="secondary" />
        </MenuItem>
      </Box>
    )
  }
  const colDefs: ColDef[] = [
    { field: "name", cellRenderer: toSpacePageRouter, flex: 2 },
    { field: "status", flex: 1 },
    { field: "total_asins", headerName: "Asins", flex: 1 },
    { field: "created_by", headerName: "Created By", flex: 1 },
    //@ts-ignore
    { field: "created_at", headerName: "Created At", valueFormatter: toHumanDate, flex: 1 },
    //@ts-ignore
    { field: "updated_at", headerName: "Updated At", valueFormatter: toHumanDate, flex: 1 },
    { field: "total_suggested", headerName: "Suggestions", flex: 1 },
    { field: "actions", headerName: "", flex: 0.5, cellRenderer: getActions },
  ]

  const handleSearch = (search: string) => {
    const filteredSpaces = spacesList?.filter(space => space.name.toLowerCase().includes(search.toLowerCase()))
    const rowData = getRowData(filteredSpaces)
    setRowData(rowData ?? [])
  }

  const spaceTableView = useSplitEnabled(Split.SPACES_TABLE_VIEW)

  return (
    <Stack height={"100vh"} sx={{ background: theme.palette.grey[50] }} display="flex">
      <SpacesHeader />
      {(spacesListLoading || spacesListFetching) && <LinearProgress />}
      {
        spaceTableView ?
          <Box
            className="ag-theme-quartz" // applying the Data Grid theme
            style={{ height: 523 }} // the Data Grid will fill the size of the parent container
            margin={5}
          >
            <Stack direction="row" sx={{
              padding: "12px 0",
              "justify-content": "space-between",
              "align-items": "center",
            }} >

              <Box >
                <CssTextField placeholder="Search Space"
                  onChange={(e) => { handleSearch(e.target.value) }}
                />
              </Box>
              <Stack direction="row" gap={4} alignItems={"flex-end"}>
                <Box width={200}>
                  {progressBarLoading ? <Skeleton></Skeleton> :
                    (<>
                      <BorderLinearProgress variant="determinate" value={100 - (100 / catalogAsinCount)} />
                      <Typography variant="caption">
                        {catalogSpaceAsinCount} out of {catalogAsinCount} ASINs
                      </Typography>
                    </>)
                  }
                </Box>
                <Button
                  variant="primary"
                  sx={{
                    height: "32px",
                    width: "200px",
                  }}
                  onClick={() => { }}
                >
                  <Link
                    to={`${SPACES_ROUTE}/add`}
                    style={{
                      textDecoration: "none",
                      color: theme.palette.grey[800],
                      display: "flex",
                      flexDirection: "row",
                      "alignItems": "center",
                    }}
                  >
                    <Typography variant="h5" color="white">+</Typography>
                    <Typography height='22px' padding="0 4px" variant="body2" color="white"> Create new Space</Typography>
                  </Link>
                </Button>
              </Stack>
            </Stack>
            <Box sx={{
              "& .ag-paging-panel": {
                "justify-content": "center"
              },
              height: "100%",
            }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={[10, 20, 50, 100]}
              />
            </Box>
          </Box >

          : <Grid
            container
            direction="row"
            justifyContent="start"
            alignItems="flex-start"
            sx={{
              padding: 4,
              // height: "100%",
              overflow: "auto",
              marginTop: "0",
            }}
            spacing={2}
          >
            <Grid item xs={2}>
              <Link
                to={`${SPACES_ROUTE}/add`}
                style={{ textDecoration: "none", color: theme.palette.grey[800] }}
              >
                <Box
                  sx={(t) => ({
                    width: "100%",
                    height: "164px",
                    border: `2px dashed ${t.palette.divider}`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "12px",
                    transition: "0.8s all-ease",
                    fontFamily: "Inter",
                    svg: {
                      color: theme.palette.primary.main,
                    },
                    "&:hover, &:hover svg": {
                      background: theme.palette.primary.main,
                      color: theme.palette.background.default,
                      border: "none",
                    },
                  })}
                >
                  <Stack justifyContent="center" alignItems="center">
                    <AddIcon
                      height={"24px"}
                      width={"24px"}
                      sx={{ flexShrink: 0 }}
                    />
                    Create Space
                  </Stack>
                </Box>
              </Link>
            </Grid>
            {spacesList?.map((space) => (
              <Grid item xs={2} key={space.id}>
                <Link
                  to={`${SPACES_ROUTE}/${space.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Box
                    sx={(t) => ({
                      padding: "8px 14px",
                      width: "100%",
                      height: "164px",
                      border: `2px solid ${t.palette.divider}`,
                      background: "white",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "space-between",
                      borderRadius: "12px",
                      cursor: "pointer",
                      "&:hover *": {
                        visibility: "visible",
                      },
                    })}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      gap="5px"
                    >
                      <Stack
                        width="100%"
                        alignItems="center"
                        justifyContent="space-between"
                        direction="row"
                        flex={1}
                      >
                        <Typography variant="headline4" py={"8px"}>
                          {space.name}
                        </Typography>
                      </Stack>
                      <IconButton
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={(e) => handleClick(e, space.id)}
                        sx={{
                          background: "none",
                          visibility: "hidden",
                          "&:hover": {
                            color: theme.palette.primary.main,
                            background: theme.palette.primary.light,
                          },
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id={`basic-menu-${space.id}`}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        transformOrigin={{ horizontal: "right", vertical: "top" }}
                        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                        sx={{
                          "& .MuiPaper-root": {
                            boxShadow: "2px 4px 7px -2px #c4c4c4",
                          },
                        }}
                      >
                        <MenuItem
                          onClick={(e) => {
                            deleteMutation
                              .mutateAsync()
                              .then(() => {
                                snackbar.enqueueSnackbar(
                                  "space deleted successfully!",
                                  {
                                    variant: "success",
                                  }
                                );
                              })
                              .catch((e) => {
                                snackbar.enqueueSnackbar("Error accrued: " + e, {
                                  variant: "success",
                                });
                              })
                              .finally(() => {
                                spacesListRefetch();
                              });
                            handleClose(e);
                          }}
                          sx={{
                            color: theme.palette.error.main,
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <DeleteIcon color={"error"} />
                          Delete
                        </MenuItem>
                      </Menu>
                    </Stack>
                    <Stack
                      direction="row-reverse"
                      spacing={1}
                      justifyContent="space-between"
                    >
                      <Box
                        sx={{
                          background: theme.palette.primary.light,
                          color: theme.palette.primary.main,
                          padding: "4px 8px",
                          borderRadius: "16px",
                          height: "24px",
                          whiteSpace: "nowrap",
                          fontSize: "10px",
                          fontFamily: 'Inter, Roboto, Helvetica, Arial, sans-serif',
                        }}
                      >
                        {space?.total_asins} ASINs
                      </Box>
                      {!!space?.total_suggested && (
                        <Link
                          to={`${SPACES_ROUTE}/${space.id}/?tabId=suggested_asins`}
                          style={{ textDecoration: "none" }}
                        >
                          <Tooltip
                            title={`You have ${space?.total_suggested} suggestion for this space`}
                          >
                            <Badge
                              color="secondary"
                              badgeContent={space?.total_suggested}
                            >
                              <TipsAndUpdatesIcon />
                            </Badge>
                          </Tooltip>
                        </Link>
                      )}
                      {/*<Box*/}
                      {/*  sx={{*/}
                      {/*    background: theme.palette.success.light,*/}
                      {/*    color: theme.palette.grey[800],*/}
                      {/*    padding: "4px 8px",*/}
                      {/*    borderRadius: "16px",*/}
                      {/*    height: "24px",*/}
                      {/*    whiteSpace: "nowrap",*/}
                      {/*    fontSize: "10px",*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  {space?.status}*/}
                      {/*</Box>*/}
                      {/*<Box*/}
                      {/*  sx={{*/}
                      {/*    background: theme.palette.grey[200],*/}
                      {/*    color: theme.palette.grey[800],*/}
                      {/*    padding: "4px 8px",*/}
                      {/*    borderRadius: "16px",*/}
                      {/*    height: "24px",*/}
                      {/*    whiteSpace: "nowrap",*/}
                      {/*    fontSize: "10px",*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  {space.type}*/}
                      {/*</Box>*/}
                    </Stack>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
      }
    </Stack >
  );
};

export default SpacesList;
