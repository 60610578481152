import { renderToString } from "react-dom/server";
import { convertNumberToShortFormat } from "../utils";
import ChartTooltip from "./ChartTooltip";
import { ChartsTypes } from "../types";

const colorsByIdx = ["#45A2F8", "#8558EE", "#66cc99"];

const getChartOptions = (graph_data: any) => {
  const genericOptions = {
    chart: {
      type: graph_data.graph_type as ChartsTypes,
      height: 430,
      toolbar: { show: false, tools: { download: false } },
    },
    plotOptions:
      graph_data.graph_type === "bar"
        ? {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
                total: { offsetX: -10 },
              },
              barHeight: "15px",
              borderRadius: 7,
            },
          }
        : {
            bar: {
              horizontal: false,
              columnWidth: "90%",
            },
          },
    dataLabels: {
      enabled: false,
      textAnchor: "start",
      offsetX: 25,
      offsetY: 1,
      style: { fontSize: "10px", colors: colorsByIdx },
      formatter: convertNumberToShortFormat,
    },
    stroke: {
      show: true,
      curve: Array.from({ length: graph_data.series.length }, () => "smooth"),
      width: Array.from({ length: graph_data.series.length }, () => 3),
      colors: colorsByIdx,
      dashArray: [...Array(graph_data.series.length).keys()].map((i) =>
        graph_data.graph_type !== "area" ? 0 : i * 5
      ),
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "right",
      fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
      onItemClick: { toggleDataSeries: !!graph_data.series?.[1] },
      markers: { fillColors: colorsByIdx },
      formatter: (value: string | number) =>
        graph_data.legend?.[value] || value,
      offsetY: -5,
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      followCursor: true,
      custom: ({ series, dataPointIndex }: any) => {
        const seriesWithTypes = series.map((serie: any, index: number) => ({
          ...serie,
          type: graph_data.axis?.y[index]?.type,
        }));
        return renderToString(
          <ChartTooltip
            series={seriesWithTypes}
            dataPointIndex={dataPointIndex}
            categories={graph_data.categories}
            graphType={graph_data.graph_type}
            legend={Object.values(graph_data.legend || {})}
          />
        );
      },
    },
    fill: {
      gradient: {
        opacityFrom: 0.7,
        opacityTo: 0,
      },
    },
    xaxis: {
      title: {
        // text: graph_data.axis.x || "Date",
        text: "",
      },
      tickAmount: Math.min(graph_data.categories?.length, 26),
      categories: graph_data.categories,
      labels: {
        rotate: -30,
        rotateAlways: graph_data.categories.length > 12,
        style: {
          colors: "#2C224C",
          fontSize: "10px",
          fontWeight: 500,
          fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
        },
        formatter: convertNumberToShortFormat,
      },
      axisBorder: { show: false },
      tooltip: { enabled: false },
    },
    colors: colorsByIdx,
  };

  let uniqueYAxis = [
    ...new Set(graph_data.axis?.y.map((axis: any) => axis.label)),
  ];

  const options = graph_data.axis
    ? {
        ...genericOptions,
        yaxis: graph_data.axis.y.map((axis: any, index: number) => {
          const labelRemoved = !uniqueYAxis.includes(axis.label);
          uniqueYAxis = uniqueYAxis.filter((item) => item !== axis.label);

          return {
            opposite: index !== 0,
            seriesName: !labelRemoved
              ? axis.series
              : graph_data.axis?.y[index - 1]?.series,
            title: {
              // text: axis.label,
              rotate: 0,
            },
            labels: labelRemoved
              ? { show: false }
              : {
                  show: true,
                  maxWidth: 230,
                  style: {
                    colors: "#2C224C",
                    fontSize: "10px",
                    fontWeight: 500,
                    fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
                  },
                  formatter: (value: string | number) => {
                    const formatted = convertNumberToShortFormat(
                      value,
                      axis.type === "growth" || axis.type === "share"
                    );
                    if (formatted === "Nan") {
                      return value;
                    }
                    return !axis.type
                      ? formatted
                      : axis.type === "currency"
                        ? `$${formatted}`
                        : axis.type === "growth" || axis.type === "share"
                          ? formatted
                          : value;
                  },
                },
            axisBorder: {
              show: false,
            },
          };
        }),
      }
    : genericOptions;

  return options;
};

export default getChartOptions;
