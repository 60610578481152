import { FC } from "react";
import { AttributeTextFieldRenderer } from "./AttributeTextFieldRenderer";
import { Features } from "../../types";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import theme from "../../../../themes/light";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import EditIcon from "@mui/icons-material/Edit";

interface AcceptableValuesFieldFormatterProps {
  index: number;
  item: Features;
  currentValue: string[] | number[];
  currentAttributes: Features[];
  setCurrentAttributes: (value: Features[]) => void;
}

const AcceptableValuesFieldFormatter: FC<
  AcceptableValuesFieldFormatterProps
> = ({
  index,
  item,
  currentValue,
  currentAttributes,
  setCurrentAttributes,
}) => {
  const allowInput =
    currentAttributes[index].closed_list &&
    currentAttributes[index].type !== "boolean";

  const allowAutoDetect =
    allowInput && currentAttributes[index].type === "string";

  const autoDetectSelected =
    currentAttributes[index].accepted_values === "auto_detect";

  const toggleAutoDetect = () => {
    if (allowAutoDetect) {
      if (autoDetectSelected) {
        const newAttributes = [...currentAttributes];
        newAttributes[index].accepted_values = [];
        setCurrentAttributes(newAttributes);
        setTimeout(
          () =>
            document
              .getElementById(
                `attribute-text-accepted_values-${index}`
              )
              ?.click(),
          0
        );
      } else {
        const newAttributes = [...currentAttributes];
        newAttributes[index].accepted_values = "auto_detect";
        setCurrentAttributes(newAttributes);
      }
    }
  };

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        gap: "10px",
      }}
    >
      {!autoDetectSelected ? (
        <AttributeTextFieldRenderer
          rowIndex={index}
          field="accepted_values"
          type={item.type}
          value={currentValue as string[] | number[]}
          setValue={(value: string[] | number[]) => {
            const newAttributes = [...currentAttributes];
            newAttributes[index].accepted_values = value;
            setCurrentAttributes(newAttributes);
          }}
          disable={!allowInput}
        />
      ) : (
        <Stack
          sx={{
            padding: "15px",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AutoAwesomeIcon
            style={{
              color: theme.palette.primary.dark,
              fontSize: 15,
            }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#000",
            }}
          >
            Noogata AI Auto Detect
          </Typography>
        </Stack>
      )}
      {allowAutoDetect && (
        <Tooltip
          title={
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 500,
                color: "white",
              }}
            >
              {!autoDetectSelected
                ? "Let Noogata AI automatically detect the accepted values for this feature"
                : "Manually set the accepted values for this feature"}
            </Typography>
          }
          placement="top"
          arrow
        >
          <Box
            id="auto-detect-button"
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={toggleAutoDetect}
              sx={{ cursor: allowAutoDetect ? "pointer" : "default" }}
            >
              {!autoDetectSelected ? (
                <AutoAwesomeIcon
                  style={{
                    color: theme.palette.primary.dark,
                    fontSize: 15,
                  }}
                />
              ) : (
                <EditIcon
                  style={{
                    fontSize: 15,
                  }}
                />
              )}
            </IconButton>
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};

export default AcceptableValuesFieldFormatter;
