import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getSalesAssistantInsightApi, useGetSummaryByLLM,
  useIsThereSalesAssistant,
  useProductsFilterList,
  useSalesAssistantHeaderData,
  useSalesAssistantList,
} from "./api.ts";
import {
  ListOutput,
  RowData,
  SalesAssistantHeaderData,
  SalesAssistantInsight,
  SalesAssistantInsightOutput,
} from "./types.ts";
import { useQueryParams } from "../../hooks";

export type SalesAssistantState = {
  // State
  searchInput: string;
  setSearchInput: Dispatch<SetStateAction<string>>;

  daysToShow: string;
  setDaysToShow: Dispatch<SetStateAction<string>>;
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
  spaceId?: string;
  brand?: string;
  selectedProduct?: RowData;
  selectedAsin?: string | undefined;
  // Data
  salesAssistantList?: ListOutput;
  productGroups?: { id: string; name: string; asins?: number }[];
  selectedProductGroup: string;
  setSelectedProductGroup: Dispatch<SetStateAction<string>>;
  selectedGroupBy: string;
  setSelectedGroupBy: Dispatch<SetStateAction<string>>;
  isThereSalesAssistant?: boolean;
  salesAssistantHeaderData?: SalesAssistantHeaderData[];
  fetchNextPage: () => void;
  salesAssistantLLMSummary? : string;
  salesAssistantLLMQaA? :  string[];
  // Loading
  salesAssistantListFetching?: boolean;
  isThereSalesAssistantLoading?: boolean;
  salesAssistantHeaderDataLoading?: boolean;
  isLoading: boolean;
  lastDayOfData?: Date;
  isSalesAssistantLLMSummaryLoading?:boolean;
  isSalesAssistantLLMQaALoading?:boolean;


};
export const SalesAssistantContext = createContext<SalesAssistantState>(
  {} as SalesAssistantState
);

export const useSalesAssistant = () => useContext(SalesAssistantContext);

const SalesAssistantProvider = ({ children }: { children: ReactNode }) => {
  // Query Params
  const queryParams = useQueryParams();
  const spaceId = queryParams.get("space") || "";
  const brand = queryParams.get("brand") || "";

  // Filter & Sort State
  const [daysToShow, setDaysToShow] = useState("7d"); // 7/28 days
  const [selectedProductGroup, setSelectedProductGroup] =
    useState("ALL_PRODUCTS");
  const [searchInput, setSearchInput] = useState("");
  const [userQuestionInput, setUserQuestionInput] = useState("");

  const [sortBy, setSortBy] = useState("-revenue");

  // Group by (sales feed flow)
  const [selectedGroupBy, setSelectedGroupBy] = useState("spaces"); // spaces/asins/brands

  useEffect(() => {
    if (spaceId || brand) {
      setSelectedGroupBy("asins");
    }
  }, [spaceId, brand]);



  // Is there sales assistant
  const {
    data: isThereSalesAssistant,
    isLoading: isThereSalesAssistantLoading,
  } = useIsThereSalesAssistant();



  // Header Cards (for the selected flow)
  const {
    data: salesAssistantHeaderData,
    isLoading: salesAssistantHeaderDataLoading,
  } = useSalesAssistantHeaderData({
    space_id: spaceId,
    product_group_id: selectedProductGroup,
    isThereSalesAssistant: !!isThereSalesAssistant,
  });

  // Selected Product Cards
  // const { data: salesCardData, isLoading: salesCardsDataLoading } =
  //   useSalesAssistantCards({
  //     isThereSalesAssistant: !!isThereSalesAssistant,
  //     space_id: spaceId,
  //     asin: queryParams.get("asin") || undefined,
  //   });

  // Sales Assistant List
  const {
    data: salesAssistantListPages,
    isLoading: salesAssistantListLoading,
    isFetching: salesAssistantListFetching,
    fetchNextPage,
  } = useSalesAssistantList({
    daysToShow,
    selectedGroupBy,
    selectedProductGroup,
    searchInput,
    spaceId,
    brand,
    sortBy,
    isThereSalesAssistant: !!isThereSalesAssistant,
  });

  const salesAssistantList = useMemo(() => {
    return salesAssistantListPages?.pages.reduce(
      (acc, val) => {
        acc.total = val.total;
        acc.data = [...acc.data, ...val.data];

        return acc;
      },
      { total: 0, data: [] } as ListOutput
    );
  }, [salesAssistantListPages]);

  // Product Groups
  const {
    data: productGroupFilterList,
    isLoading: productGroupFilterListLoading,
  } = useProductsFilterList({ isThereSalesAssistant });

  const productGroups = useMemo(
    () =>
      productGroupFilterList?.reduce<
        { id: string; name: string; asins?: number }[]
      >(
        (acc, val) => {
          acc.push({ id: val.id, name: val.name, asins: val.total });
          return acc;
        },
        [
          {
            id: "ALL_PRODUCTS",
            name: "All Products & Groups",
          },
        ]
      ),
    [productGroupFilterList]
  );

  const isLoading = useMemo(
    () =>
      salesAssistantListLoading ||
      productGroupFilterListLoading ||
      salesAssistantHeaderDataLoading,
    [
      salesAssistantListLoading,
      productGroupFilterListLoading,
      salesAssistantHeaderDataLoading,
    ]
  );

  // Selected Product
  const selectedAsin = queryParams.get("asin") || undefined;

  const selectedProduct = useMemo(() => {
    return salesAssistantList?.data.find(
      (product) => product.title === selectedAsin
    );
  }, [salesAssistantList, selectedAsin]);

  const lastDayOfData = useMemo(() => {
    const dates = salesAssistantHeaderData?.[0].categories
    const ts = dates?.[dates?.length - 1]
    // return explicit undefiend otherwise ts thinks it can be empty-string
    return ts ? new Date(Number(BigInt(ts) / 1000n)) : undefined
  }, [salesAssistantHeaderData])


  const {
    data: salesAssistantLLMSummary ,
    isLoading: isSalesAssistantLLMSummaryLoading
  } =  useGetSummaryByLLM({asin:selectedAsin});



  const value = {
    // State
    userQuestionInput,
    setUserQuestionInput,
    searchInput,
    setSearchInput,
    daysToShow,
    setDaysToShow,
    sortBy,
    setSortBy,
    spaceId,
    brand,
    selectedAsin,
    selectedProduct,
    // Data
    salesAssistantList,
    productGroups,
    selectedProductGroup,
    setSelectedProductGroup,
    selectedGroupBy,
    setSelectedGroupBy,
    isThereSalesAssistant,
    salesAssistantHeaderData,
    fetchNextPage,
    salesAssistantLLMSummary,
    // Loading
    salesAssistantListFetching,
    isThereSalesAssistantLoading,
    salesAssistantHeaderDataLoading,
    isLoading,
    lastDayOfData,
    isSalesAssistantLLMSummaryLoading,

  };
  return (
    <SalesAssistantContext.Provider value={value}>
      {children}
    </SalesAssistantContext.Provider>
  );
};

export default SalesAssistantProvider;
