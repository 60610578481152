import { SalesAssistantInsight, TabConfig } from "../../types.ts";

export const tabsConfig: TabConfig[] = [
  {
    title: "Share of Voice",
    value_name: "share_of_voice",
    unit: "%",
    type: "change_value",
    data: [
      {
        title: "How is my share of voice performing this year?",
        question_id: SalesAssistantInsight.SHARE_OF_VOICE_PERFORMANCE,
        closeable: false,
        default_closed: false,
      },
      {
        title:
          "What are the keywords that will allow me to increase or maintain share of voice? See keywords you want to include increase bids or include in your advertising strategy? Add a task and track the impact.",
        question_id: SalesAssistantInsight.SHARE_OF_VOICE_KEYWORDS,
        closeable: false,
        default_closed: false,
      },
    ],
  },
  {
    title: "Competitive Traffic",
    value_name: "competitive_traffic",
    type: "change_value",
    data: [
      {
        title:
          "Here are the top competitors identified by Noogata. To fine-tune the list please contact your customer support agent",
        question_id: SalesAssistantInsight.TOP_COMPETITORS,
        closeable: false,
        default_closed: false,
      },
      {
        title:
          "How is consumer demand trending for the overall competitive space? Should I continue making bets here?",
        question_id: SalesAssistantInsight.COMPETITIVE_SPACE,
        closeable: false,
        default_closed: false,
      },
      {
        title:
          "What are the top keywords targeted by my competitors that I have overlooked? See keywords you want to include in your strategy? Add a task and track the impact.",
        question_id: SalesAssistantInsight.COMPETITORS_KEYWORDS,
        closeable: false,
        default_closed: false,
      },
    ],
  },
  {
    title: "Price",
    value_name: "avg_price",
    unit: "$",
    type: "percentage",
    data: [
      {
        title: "What price drove the most sales in the last 30 days?",
        question_id: SalesAssistantInsight.MOST_SALES_PRICE_30D,
        closeable: false,
        default_closed: false,
      },
      {
        title: "What average price drove the most sales in the last 90 days?",
        question_id: SalesAssistantInsight.MOST_SALES_PRICE_90D,
        closeable: false,
        default_closed: false,
      },
    ],
  },
  {
    title: "Ad spend",
    value_name: "ad_spend",
    unit: "$",
    type: "percentage",
    data: [
      {
        title: "Identify the impact of ad spend on your units sold",
        question_id: SalesAssistantInsight.WEEKLY_AD_SPEND,
        closeable: false,
        default_closed: false,
      },
      {
        title: "Discover daily ad spend and sales anomalies",
        question_id: SalesAssistantInsight.DAILY_AD_SPEND,
        closeable: false,
        default_closed: false,
      },
      {
        title: "Identify how your CPC impacts your total clicks",
        question_id: SalesAssistantInsight.WEEKLY_CPC,
        closeable: false,
        default_closed: false,
      },
      {
        title: "Spot daily CPC and clicks anomalies",
        question_id: SalesAssistantInsight.DAILY_CPC,
        closeable: false,
        default_closed: false,
      },
    ],
  },
  {
    title: "Conversion",
    value_name: "conversion",
    unit: "%",
    type: "percentage",
    data: [
      {
        title: "Identify if you are having issues converting your glance views",
        question_id: SalesAssistantInsight.WEEKLY_CONVERSION,
        closeable: false,
        default_closed: false,
      },
      {
        title: "Discover daily glance views and conversion anomalies",
        question_id: SalesAssistantInsight.DAILY_CONVERSION,
        closeable: true,
        default_closed: true,
      },
    ],
  },
];
