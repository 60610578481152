import { Stack, TextField, Typography } from "@mui/material";
import theme from "../../../../themes/light";
import { useEffect, useState } from "react";

interface AttributeTextFieldRendererProps {
  rowIndex: number;
  field: "attribute" | "description" | "accepted_values";
  type: "string" | "int" | "float" | "boolean";
  value: string | string[] | number[];
  setValue: any;
  disable?: boolean;
}

export const AttributeTextFieldRenderer: React.FC<
  AttributeTextFieldRendererProps
> = ({ rowIndex, field, value, setValue, disable = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");

  const updateInputValue = (value: string | string[] | number[]) => {
    if (field === "accepted_values") {
      setInputValue((value as string[] | number[]).join(", "));
    } else {
      setInputValue(value as string);
    }
  };

  useEffect(() => {
    updateInputValue(value);
  }, [value]);

  const handleApply = () => {
    if (!inputValue) {
      setInputValue(value as string);
      setIsEditing(false);
      return;
    }
    setValue(
      field !== "accepted_values"
        ? inputValue.trim()
        : inputValue
            .split(",")
            .filter((v) => v.trim())
            .map((v) => v.trim())
    );
    setIsEditing(false);
    updateInputValue(value);
  };

  const textSx =
    field === "attribute"
      ? {
          fontSize: "14px",
          fontWeight: 600,
          color: theme.palette.primary.dark,
        }
      : {
          fontSize: "14px",
          fontWeight: 400,
          color: "#000",
        };

  const formattedValue = !value
    ? "-"
    : value instanceof Array
      ? value.join(", ")
      : value;

  return (
    <Stack
      sx={{
        height: "100%",
        width: "100%",
        flexDirection: "row",
        alignItems: "start",
        padding: "10px 0",
      }}
    >
      {!isEditing ? (
        <Stack
          id={`attribute-text-${field}-${rowIndex}`}
          className={`attribute-text-field`}
          sx={{
            width: "100%",
            height: "fit-content",
            minHeight: "40px",
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            padding: "4px 10px",
            borderRadius: "4px",
            "&:hover":
              !isEditing && !disable
                ? {
                    backgroundColor: theme.palette.primary.light,
                    cursor: "pointer",
                  }
                : {},
          }}
          onClick={() => {
            if (!disable) {
              setIsEditing(true);
            }
          }}
        >
          <Typography
            sx={{
              borderRadius: "8px",
              display: "inline-flex",
              padding: "4px",
              ...textSx,
            }}
          >
            {formattedValue}
          </Typography>
        </Stack>
      ) : (
        <TextField
          autoFocus
          value={inputValue}
          multiline={field !== "attribute"}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          placeholder={
            field === "attribute"
              ? "Enter attribute name"
              : field === "description"
                ? "Enter description"
                : "Enter accepted values"
          }
          onBlur={handleApply}
          inputProps={{ style: textSx }}
          InputLabelProps={{ style: textSx }}
          sx={{
            width: "100%",
            borderRadius: "4px",
            padding: 0,
            "& .MuiInputBase-root":
              field !== "attribute"
                ? {
                    padding: "8px 14px",
                  }
                : {},
          }}
        />
      )}
    </Stack>
  );
};
