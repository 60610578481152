export enum SelectedView {
  MarketBreakdown = "Market Breakdown",
  BrandStrategy = "Brand Strategy",
  ProductsReview = "Products Review",
  PricingStrategy = "Pricing Strategy",
}

export interface TableColumn {
  name: string;
  type: "string" | "number" | "currency" | "percentage" | "boolean";
  description?: string;
}

export enum PeriodFilter {
  last_30_days = "30",
  last_90_days = "90",
  year_to_date = "YTD",
}

export interface AssortmentFilters {
  period?: PeriodFilter;
  brand?: string[];
  price_range?: string[];
  individual_attributes?: Record<string, any[]>;
}

export interface FilterOptions {
  period: PeriodFilter[];
  brand: string[];
  price_range: string[];
  individual_attributes: Record<string, any[]>;
}

export interface MarketBreakdownAssortment {
  attribute: string;
  table_schema: TableColumn[];
  table_data: Record<string, any>[];
}

export interface BrandStrategyAssortment {
  estimated_revenue: Record<string, any>[];
  pricing_table_schema: TableColumn[];
  pricing_table_data: Record<string, any>[];
}

export interface SalesPerformanceAssortment {
  table_schema: TableColumn[];
  table_data: Record<string, any>[];
}


export interface ProductsReviewAssortment {
  table_schema: TableColumn[];
  table_data: Record<string, any>[];
}

export interface PricingDistributionAssortment {
  distribution_data: Record<string, any>[];
}

export interface RevenueShareAssortment {
  attribute: string;
  table_schema: TableColumn[];
  table_data: Record<string, any>[];
}

export interface Product {
  asin: string;
  image_url: string;
}