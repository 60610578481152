import { Box, IconButton, Skeleton } from "@mui/material";
import { RowData } from "../../types";
import ProductInfo from "./ProductInfo";
import ProductSpaces from "./ProductSpaces";
import NumberSection from "../NumberSection";
import { LaunchProductIcon } from "../../../../assets";
import { useState } from "react";
import { useSalesAssistant } from "../../SalesAssistantProvider";
import { useHistory } from "react-router-dom";

const ProductRowSkeleton = ({ spaceId }: { spaceId?: string }) => (
  <>
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        width: "200px",
        height: "84px",
        paddingLeft: "20px",
      }}
    >
      <Skeleton
        variant="circular"
        sx={{
          borderRadius: "8px",
          height: "20px",
          width: "200px",
          transform: "unset",
        }}
      />
    </Box>
    {!spaceId && (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "175px",
          paddingLeft: "20px",
        }}
      >
        {Array.from({ length: 4 }).map((_, index) => (
          <Skeleton
            key={`skeleton-product-row-${index}-space-icon`}
            variant="circular"
            sx={{
              borderRadius: "50%",
              height: "40px",
              width: "40px",
              transform: "unset",
              marginLeft: index === 0 ? "0px" : "-10px",
            }}
          />
        ))}
      </Box>
    )}
    {Array.from({ length: 6 }).map((_, index) => (
      <Box
        key={`skeleton-product-row-${index}-number-section`}
        sx={{
          width: "120px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Skeleton
          variant="text"
          sx={{
            borderRadius: "8px",
            height: "20px",
            width: "120px",
            transform: "unset",
          }}
        />
      </Box>
    ))}
    <Box
      sx={{
        width: "30px",
      }}
    ></Box>
  </>
);

const ProductRow = ({ data }: { data?: RowData }) => {
  const history = useHistory();
  const { spaceId } = useSalesAssistant();

  const handleProductClick = () => {
    if (data) {
      const currentPath = history.location.pathname + history.location.search;
      const separator = currentPath.includes("?") ? "&" : "?";
      history.push(`${currentPath}${separator}asin=${data.title}`);
    }
  };

  const [showLaunchProduct, setShowLaunchProduct] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        backgroundColor: "white",
        border: "1px solid #E1E6ED",
        height: "84px",
        padding: "10px",
        cursor: data ? "pointer" : "default",
        "&:hover": data
          ? {
              backgroundColor: "#F4F3F8",
            }
          : {},
      }}
      onClick={handleProductClick}
      onMouseEnter={() => setShowLaunchProduct(true)}
      onMouseLeave={() => setShowLaunchProduct(false)}
    >
      {data ? (
        <>
          <ProductInfo data={data} />
          {!spaceId && (
            <Box sx={{ paddingLeft: "20px" }}>
              <ProductSpaces
                spaceIds={data?.space_ids}
                imageUrl={data?.image_url}
              />
            </Box>
          )}
          <NumberSection
            unit="$"
            number={data?.revenue}
            percentage={data?.revenue_percentage}
            change={data?.revenue_change}
            sx={{
              paddingLeft: "20px",
              width: "120px",
            }}
          />
          <NumberSection
            number={data?.units_sold}
            percentage={data?.units_sold_percentage}
            change={data?.units_sold_change}
            sx={{
              paddingLeft: "20px",
              width: "120px",
            }}
          />
          <NumberSection
            unit="$"
            number={data?.avg_price}
            percentage={data?.avg_price_percentage}
            change={data?.avg_price_change}
            sx={{
              paddingLeft: "20px",
              width: "120px",
            }}
          />
          <NumberSection
            unit="%"
            number={data?.acos}
            percentage={data?.acos_percentage}
            change={data?.acos_change}
            sx={{
              paddingLeft: "20px",
              width: "120px",
            }}
          />
          <NumberSection
            unit="%"
            number={data?.conversion}
            percentage={data?.conversion_percentage}
            change={data?.conversion_change}
            sx={{
              paddingLeft: "20px",
              width: "120px",
            }}
          />
          <NumberSection
            unit="$"
            number={data?.ad_spend}
            percentage={data?.ad_spend_percentage}
            change={data?.ad_spend_change}
            sx={{
              paddingLeft: "20px",
              width: "120px",
            }}
          />
          <Box
            sx={{
              width: "30px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              position: "relative",
              visibility: showLaunchProduct ? "visible" : "hidden",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleProductClick();
            }}
          >
            <IconButton
              sx={{
                background: "none",
                position: "absolute",
                top: "-15px",
                right: "15px",
              }}
            >
              <LaunchProductIcon />
            </IconButton>
          </Box>
        </>
      ) : (
        <ProductRowSkeleton spaceId={spaceId} />
      )}
    </Box>
  );
};

export default ProductRow;
