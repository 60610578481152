import {Box, CircularProgress, Typography} from "@mui/material";
import {useSalesAssistant} from "../../SalesAssistantProvider.tsx";
import ReactMarkdown from "react-markdown";


const Index = () => {
  const {
    salesAssistantLLMSummary,isSalesAssistantLLMSummaryLoading
  } = useSalesAssistant();

  return (
<Typography>
  {
    isSalesAssistantLLMSummaryLoading
        ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <CircularProgress />
        </Box>
      )
        : (
        salesAssistantLLMSummary && (
          <ReactMarkdown>{salesAssistantLLMSummary}</ReactMarkdown>
        )
      )}


</Typography>
  );
};

export default Index;
